/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-one-iteration-loop */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef } from "react";
import { CoworkerRequired, getDocument } from "../../api/user";
import { AppendEllipse, b64toBlob, getContentType } from "../../utils/utils";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import {
  ParentHeaderData,
  ChildHeaderData,
  ParamType,
} from "../../constants/constants";
import ReceiptDetailsException from "./ReceiptDetailsException";
import { useHistory } from "react-router-dom";
import {
  Card,
  Grid,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACButton,
  RACCheckBox,
  RACModalCard,
} from "@rentacenter/racstrap";
import LoaderGrid from "./LoaderGrid";
import { useParams } from "react-router-dom";
import { getCustomerReceipts, StoreswipeDevice } from "../../api/user";
import { selectAppender } from "../../utils/utils";
import { GeneralStateContext } from "../../context/GeneralProvider";
import { GeneralStateDispatchContext } from "../../context/GeneralProvider";
import { ReactComponent as AccordianCloseIcon } from "../../assets/images/Accordion-close.svg";
import { ReactComponent as AccordianOpenIcon } from "../../assets/images/Accordion-down.svg";
import moment from "moment";

export default function GridComponent() {
  const history = useHistory();
  const { customerId } = useParams<ParamType>();
  const classes = reversePaymentStyle();
  const donationsType = ['Fill The Fridge', 'Boys and Girls Club', 'Big Brother/Big Sister'];
  const {
    accordianIndex,
    reversePayData,
    selectedMasterCheckBox,
    individualRowTotal,
    selectedChildCheckBox,
    openReceipt,
    blobURL,
    receiptDetailsException,
    selectedAgreements,
    exceptionModal,
    loadingModal,
    tenderTypesUsed,
    isSIMSReceipt,
    choosenLineItems,
    disableReversePayment,
    disableMasterCheck,
    // isCloudStoreSwipeDeviceList,
    selectedInventories
  } = useContext(GeneralStateContext);
  const {
    setAccordianIndex,
    setReversePayData,
    setSelectedMasterCheckBox,
    setIndividualRowTotal,
    setSelectedChildCheckBox,
    setLoadingModal,
    setExceptionModal,
    setOpenReceipt,
    setBlobURL,
    setSelectedAgreements,
    setReversePayOptions,
    setReceiptDetailsException,
    setIsClubReversible,
    setTenderTypeUsed,
    // setIsSIMSReceipt,
    setChoosenLineItems,
    setOtherAmountToBeAdded,
    setOtherAmountToBeSub,
    setOtherSusPayAmt,
    setOpenTransferException,
    setDisableReversePayment,
    setAvailableSwipeDevices,
    setIsVoidFlow,
    setDisableMasterCheck,
    setDonationAmountToRefund,
    setSelectedInventories,
    setIsCloudReverseCCPayment,
    setIsCloudStoreSwipeDeviceList,
    setIsCloudReverseSwipePayment,
    setIsSwipeCustomerCard
  } = useContext(GeneralStateDispatchContext);
  const documentFrame = useRef<HTMLIFrameElement>(null);
  const dynamicViewClass = disableReversePayment ? classes.disablePage : "";
  const storeNumber: any = String(window.sessionStorage.getItem("storeNumber"));
  const configPermissions = {
    IsCloudReverseCCPayment: 0,
    IsCloudStoreSwipeDeviceList: 0,
    IsCloudReverseSwipePayment: 0,
    IsSwipeCustomerCard: 0
  };
  useEffect(() => {
    (async () => {

      const configRequest = {
        storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
        paramKeyNames: ['CloudReverseCCPayment', 'CloudStoreSwipeDeviceListReversal', 'CloudSwipeCustomerCardReversal', 'SwipeCustomerCard']
      }
      let configResponse
      
      if (storeNumber &&  storeNumber != null && storeNumber != undefined && storeNumber != '' && storeNumber != "undefined" && storeNumber != "null") {
       configResponse = await CoworkerRequired(configRequest);
      }
      if (
        configResponse?.storeProfileResponse?.configDetails.length &&
        configResponse?.storeProfileResponse?.configDetails[0].configDetails
          ?.length
      ) {
        configResponse.storeProfileResponse.configDetails[0].configDetails.map(
          (el) => {
            if (el.paramKeyName == "CloudReverseCCPayment") {
              setIsCloudReverseCCPayment(Number(el.paramValue));
              configPermissions.IsCloudReverseCCPayment=Number(el.paramValue);
            }
            if (el.paramKeyName == "CloudStoreSwipeDeviceListReversal") {
              setIsCloudStoreSwipeDeviceList(Number(el.paramValue));
              configPermissions.IsCloudStoreSwipeDeviceList=Number(el.paramValue)
            }
            if (el.paramKeyName == "CloudSwipeCustomerCardReversal") {
              setIsCloudReverseSwipePayment(Number(el.paramValue));
              configPermissions.IsCloudReverseSwipePayment=Number(el.paramValue);
            }
            if(el.paramKeyName == 'SwipeCustomerCard'){
              setIsSwipeCustomerCard(Number(el.paramValue))
            }
          }
        );
        console.log("cdfwev", configResponse);
      }

      const customerReceiptResponse = await getCustomerReceipts(customerId);
      console.log("customerReceiptResponse logger", customerReceiptResponse);

      if (
        customerReceiptResponse.status === 200 &&
        customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes
      ) {
        const hasVoidFlow =
          customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes[0].paymentReceiptDetails.some(
            (el) =>
              el.agreementStatus == "PRELIMPAID" ||
              el.agreementStatus == "PRELIMINV"
          );
        setIsVoidFlow(hasVoidFlow);
        if (
          customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes[0]
            .transferAgreementStatus == "1"
        ) {
          setOpenTransferException(true);
          setDisableReversePayment(true);
        }

        // setIsSIMSReceipt(
        //   customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes[0]
        //     .createdSource === "ENTERPRISE"
        // );

        let otherAmountToAdd = 0;
        let otherAmountToSubtract = 0;
        let otherSusPayAmount = 0;
        if (
          customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes[0]
            .otherPaymentAmount.length
        ) {
          customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes[0].otherPaymentAmount.map(
            (obj) => {
              if (
                obj.accountType == "CONVENFEE" ||
                obj.accountType == "CONVENFEETAX" ||
                obj.accountType == "SUSADD" ||
                obj.accountType == "CREDONACCADD"
              ) {
                otherAmountToAdd = otherAmountToAdd + Number(obj.otherAmount);
              } else if (
                obj.accountType == "CREDONACCSUB" ||
                obj.accountType == "WEBLEADSUB"
              ) {
                otherAmountToSubtract =
                  otherAmountToSubtract + Number(obj.otherAmount);
              } else if (obj.accountType == "SUSPAY") {
                otherSusPayAmount =
                  otherSusPayAmount + Number(obj.otherAmount);
              }
            }
          );
        }
        setOtherAmountToBeAdded(otherAmountToAdd);
        setOtherAmountToBeSub(otherAmountToSubtract);
        setOtherSusPayAmt(otherSusPayAmount);
        //setOtherPayAmount(otherAmountToAdd);

        const isSwipeCardUsed =
        customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes[0].tenderedTypesUsed.cardDetails.filter(
          (x) => x?.cardTransactionType == "SWP"
        );

        if (isSwipeCardUsed.length && configPermissions.IsSwipeCustomerCard==1) {
          const getSwipeDeviceList = await StoreswipeDevice(
            {
              storeNumber:window.sessionStorage.getItem("storeNumber"),
              cloudStoreSwipeDevicelist:configPermissions.IsCloudStoreSwipeDeviceList==1? 'Y':'N'
            }
            
          );
          if(configPermissions.IsCloudStoreSwipeDeviceList == 0) {
            if (
              getSwipeDeviceList.status === 200 &&
              getSwipeDeviceList.data !== ""
            ) {
              console.log("getSwipeDeviceList", getSwipeDeviceList);
              const finalTerminalValue = await selectAppender(
                getSwipeDeviceList?.data?.StorePaymentDeviceResponse?.devices
              );
              setAvailableSwipeDevices(finalTerminalValue);
            } else if (
              getSwipeDeviceList.status === 400 ||
              getSwipeDeviceList.status === 500 ||
              getSwipeDeviceList.data == ""
            ) {
              setAvailableSwipeDevices([{
                description: "No reasons",
                referenceCode: "SELECT",
              }]);
            }
          } else {
            if (
              getSwipeDeviceList.status === 200 &&
              getSwipeDeviceList.data !== undefined &&
              getSwipeDeviceList.data.devices &&
              getSwipeDeviceList.data.devices.length > 0
            ) {
              const finalTerminalValue = await selectAppender(
                getSwipeDeviceList?.data?.devices
              );
              setAvailableSwipeDevices(finalTerminalValue);
            } else {
              setAvailableSwipeDevices([{
                description: "No reasons",
                referenceCode: "SELECT",
              }]);
            }
          }
        }

        const finalReasonValue = await selectAppender(
          customerReceiptResponse.data.reverseReason.references[0]
            .referenceDetails
        );
        setReversePayOptions(finalReasonValue);

        setReversePayData(
          customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes
        );

        const isCashPresent =
          Number(
            customerReceiptResponse.data.customerReceipt
              .getCustomerReceiptsRes[0].tenderedTypesUsed.cash
          ) > 0;

        console.log("isCashPresent", isCashPresent);

        const filteredDonationData =
          customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes[0].paymentReceiptDetails.filter(
            (el) =>
              donationsType.includes(el.description) &&
              el.isReversed === false &&
              el.isReversible === true
          );
        setChoosenLineItems(filteredDonationData);
        const calculatedDonationRefund = filteredDonationData.reduce((acc, el) => acc + el.amountToReturn, 0);
        setDonationAmountToRefund(Number(calculatedDonationRefund));

        const isCardPresent =
          customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes[0]
            .tenderedTypesUsed.cardDetails.length > 0;

        console.log("isCardPresent", isCardPresent);

        const isCheckMOPresent =
          Number(
            customerReceiptResponse.data.customerReceipt
              .getCustomerReceiptsRes[0].tenderedTypesUsed.moneyOrder
          ) > 0 ||
          Number(
            customerReceiptResponse.data.customerReceipt
              .getCustomerReceiptsRes[0].tenderedTypesUsed.check
          ) > 0;
        console.log("isCheckMOPresent", isCheckMOPresent);
        // setIsCheckMOUsed(isCheckMOPresent);

        setTenderTypeUsed({
          ...tenderTypesUsed,
          cash: isCashPresent,
          check: isCheckMOPresent,
          card: isCardPresent,
        });

        if(filteredDonationData.length) {
          setIndividualRowTotal([String(calculatedDonationRefund)]);
        } else {
          setIndividualRowTotal(
            new Array(
              customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes.length
            ).fill("0.00")
          );
        }

        const getBooleanData: any = [];
        const copyVal = [
          ...customerReceiptResponse.data.customerReceipt
            .getCustomerReceiptsRes,
        ];
        copyVal.map((mappedValue) => {
          const stem = new Array(mappedValue.paymentReceiptDetails.length).fill(
            false
          );
          mappedValue.paymentReceiptDetails.map((detailedMap, index) => {
            if (detailedMap.isReversed === true || donationsType.includes(detailedMap.description)) {
              stem[index] = true;
            }
          });
          getBooleanData.push(stem);
        });
        console.log("getBooleanData value", getBooleanData);
        setSelectedChildCheckBox(getBooleanData);
      } else if (
        customerReceiptResponse.status === 200 &&
        customerReceiptResponse.data.customerReceipt.getCustomerReceiptsRes ===
          undefined
      ) {
        setReceiptDetailsException({
          ...receiptDetailsException,
          notEligible: true,
        });
      } else if (customerReceiptResponse.status === 500) {
        setReceiptDetailsException({
          ...receiptDetailsException,
          serverError: true,
        });
      }
    })();
  }, []); // SQ-2 This method will be getting the reverse payment details & the reverse reason drop down value.

  useEffect(() => {
    console.log('reversePayData loger', reversePayData);
    if(reversePayData) {
      const disableMasterCheckResult = reversePayData[0].paymentReceiptDetails.some((el) => el.isReversed || (!el.isReversed && !el.isReversible) || donationsType.includes(el.description));
      setDisableMasterCheck(disableMasterCheckResult);
      console.log('disableMasterCheck', disableMasterCheckResult);
    }
  }, [reversePayData]);

  const renderParentHeader = () => (
    <>
      {console.log("scfdxcvfds")}
      {ParentHeaderData.map((col: string, index: number) => {
        if (col !== "Accordian") {
          return (
            <RACTableCell
              key={index}
              className={
                col == "Action"
                  ? `${classes.textCenter} ${classes.actionWidth}`
                  : ""
              }
            >
              {col}
            </RACTableCell>
          );
        } else {
          return (
            <RACTableCell
              key={index}
              className={`${classes.emptyWidth}`}
            ></RACTableCell>
          );
        }
      })}
    </>
  ); // SQ-3 This method will be rendering the header part of the reverse payment grid

  const renderParentContent = (): any => {
    return reversePayData.map((obj, index) => {
      return (
        <React.Fragment key={index}>
          <RACTableRow
            key={index}
            className={
              accordianIndex.includes(index)
                ? `${classes.customerTablecellbgcolor} ${classes.cusomerInfo}`
                : `${classes.customerTablecellbgcolor}`
            }
          >
            <RACTableCell
              className={
                isSIMSReceipt
                  ? `${classes.cursorDisable}`
                  : `${classes.cursorPointer}`
              }
            >
              {accordianIndex.includes(index) ? (
                <AccordianOpenIcon
                  onClick={() => accordianClicked(index)}
                  className={
                    isSIMSReceipt
                      ? `${classes.cursorDisableEvent}`
                      : `${classes.cursorPointer}`
                  }
                />
              ) : (
                <AccordianCloseIcon
                  onClick={() => accordianClicked(index)}
                  className={
                    isSIMSReceipt
                      ? `${classes.cursorDisableEvent}`
                      : `${classes.cursorPointer}`
                  }
                />
              )}
            </RACTableCell>
            <RACTableCell className={`${classes.breadcrumbItemActive}`}>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => invokeDocumentAPI(obj.receiptId)}
              >
                {" "}
                {obj.receiptId}{" "}
              </a>
            </RACTableCell>
            <RACTableCell className={`${classes.gridLabel}`}>
              {" "}
              {moment(obj.receiptDate).format("MM/DD/YYYY")}
            </RACTableCell>
            <RACTableCell className={`${classes.textCenter}`}>
              <RACButton
                variant="contained"
                color="primary"
                className={`${classes.rcptButton}`}
                onClick={() => invokeDocumentAPI(obj.receiptId)}
              >
                Display Receipt
              </RACButton>
            </RACTableCell>
          </RACTableRow>
          {bindChildGrid(
            accordianIndex.includes(index),
            index,
            obj.paymentReceiptDetails
          )}
          {accordianIndex.includes(index) ? (
            <RACTableRow key={index}>
              <RACTableCell></RACTableCell>
              <RACTableCell></RACTableCell>
              <RACTableCell></RACTableCell>
              <RACTableCell
                className={`${classes.gridLabel} ${classes.chkBoxAlign} ${classes.w20}`}
              >
                Amount To Return: $ {individualRowTotal[index]}
              </RACTableCell>
            </RACTableRow>
          ) : null}
        </React.Fragment>
      );
    });
  }; //SQ-4 This method will be rendering the content part of the reverse payment grid

  const bindChildGrid = (showDetails: any, index: any, detailedData: any) => {
    console.log("sdfdsd", showDetails);

    return showDetails ? (
      <RACTableRow
        className={`${classes.customerTablecellbgcolor} ${classes.cusomerInfoTab}`}
      >
        {console.log("Reached")}
        <RACTableCell colSpan={15}>
          <RACTable
            renderTableHead={() => renderChildHeader(index)}
            renderTableContent={() => renderChildContent(index, detailedData)}
          />
        </RACTableCell>
      </RACTableRow>
    ) : null;
  }; // SQ-6 This method will be rendering the child grid of reverse payment.

  const renderChildHeader = (indexFromMasterGrid: any) => (
    <>
      {ChildHeaderData.map((col: string) => {
        if (col !== "Check Box") {
          return (
            <RACTableCell
              key={indexFromMasterGrid}
              className={
                col == "Amount To Return"
                  ? `${classes.chkBoxAlign}`
                  : col == "Next Due Date"
                  ? `${classes.dueAlign}`
                  : ""
              }
            >
              {col}
            </RACTableCell>
          );
        } else {
          return (
            <RACTableCell
              key={indexFromMasterGrid}
              className={`${classes.chkBoxAlign}`}
            >
              <RACCheckBox
                checked={
                  selectedMasterCheckBox.includes(indexFromMasterGrid)
                    ? true
                    : false
                }
                disabled={disableMasterCheck}
                onClick={() => masterCheckBoxHandle(indexFromMasterGrid)}
              />
            </RACTableCell>
          );
        }
      })}
    </>
  ); // SQ-7  This method will be rendering the header content of the child grid of reverse payment.

  const renderChildContent = (
    indexFromMasterGrid: any,
    detailedData: any
  ): any => {
    console.log("sdfsindexFromMasterGrid", indexFromMasterGrid);
    console.log("reversePayData in grid", reversePayData);

    return detailedData.map((obj, index) => {
      return (
        <React.Fragment key={index}>
          <RACTableRow
            key={index}
            className={`${classes.customerTablecellbgcolor}`}
          >
            <RACTableCell className={`${classes.chkBoxAlign}`}>
              <RACCheckBox
                onClick={(e) =>
                  childCheckBoxHandle(
                    e,
                    indexFromMasterGrid,
                    index,
                    obj.amountToReturn,
                    obj.agreementId,
                    obj.inventoryId,
                    obj.paymentType,
                    obj
                  )
                }
                disabled={
                  obj.isReversed
                    ? true
                    : !obj.isReversed && !obj.isReversible
                    ? true 
                    : donationsType.includes(obj.description)
                    ? true
                    : false
                }
                checked={selectedChildCheckBox[indexFromMasterGrid][index]}
              />
            </RACTableCell>
            <RACTableCell className={obj?.agreementId || obj?.inventoryId ? `${classes.breadcrumbItemActive}` : `${classes.gridLabel}`}>
              {obj?.agreementId ?
                <a style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/agreement/info/details/${customerId}/${obj.agreementId}`)}>
                  {obj.paymentSource}
                </a> :
                obj?.inventoryId ?
                  <a style={{ cursor: "pointer" }}
                    onClick={() => history.push(`/inventory/${obj.paymentSource}/inventoryinformation`)}
                  >
                    {obj.paymentSource}
                  </a>
                  : <span> {obj.paymentSource} </span>
              }
            </RACTableCell>
            <RACTableCell className={`${classes.gridLabel}`}>
              {obj.paymentType == "Rental Agreement (RTO)"
                ? "Rent"
                : obj.paymentType}
            </RACTableCell>
            <RACTableCell
              className={
                obj.description?.length < 15
                  ? `${classes.gridLabel}`
                  : `${classes.gridLabel} ${classes.cursorPointer}`
              }
              title={obj.description?.length < 15 ? "" : obj.description}
            >
              {obj.description?.length < 15
                ? obj.description
                : AppendEllipse(obj.description, 15, "...")}
            </RACTableCell>
            <RACTableCell
              className={`${classes.dueAlign} ${classes.gridLabel}`}
            >
              {obj.nextDueDate == null || obj.nextDueDate == ''
                ? "-"
                : moment(obj.nextDueDate).format("MM/DD/YYYY")}
            </RACTableCell>
            <RACTableCell
              className={`${classes.chkBoxAlign} ${classes.gridLabel}`}
            >
              {!obj.isReversed && obj.isReversible ? "$" : null}{" "}
              {obj.isReversed && obj.isReversible
                ? "Reversed"
                : !obj.isReversed && !obj.isReversible
                ? "Not Eligible"
                : !obj.isReversed && obj.isReversible
                ? obj.amountToReturn
                : null}
            </RACTableCell>
          </RACTableRow>
        </React.Fragment>
      );
    });
  }; // SQ-8 This method will be rendering the body content of the child grid of reverse payment.

  const accordianClicked = (index: any) => {
    const colVal = [...[], ...accordianIndex];
    if (!colVal.includes(index)) {
      colVal.push(index);
    } else {
      colVal.splice(colVal.indexOf(index), 1);
    }
    setAccordianIndex(colVal.sort());
  }; // SQ-5 This method will be collecting the clicked accordian's index.

  const masterCheckBoxHandle = (index: any) => {
    const colVal = [...[], ...selectedMasterCheckBox];
    if (!colVal.includes(index)) {
      colVal.push(index);
      const iniVal: any = [...[], ...selectedChildCheckBox];
      iniVal[index].fill(true);
      setSelectedChildCheckBox(iniVal);

      let collectedTotalValue: any = 0;
      const selectedAgreementId: any = [];
      const selectedInvId: any = [];
      const updateChoosenLineData: any = [];
      reversePayData[index].paymentReceiptDetails.map((detailedObj) => {
        if (!detailedObj.isReversed && detailedObj.isReversible) {
          collectedTotalValue =
            collectedTotalValue + Number(detailedObj.amountToReturn);
          if (detailedObj.agreementId) {
            selectedAgreementId.push(detailedObj.agreementId);
          }
          if (detailedObj.inventoryId) {
            selectedInvId.push(detailedObj.inventoryId);
          }
          updateChoosenLineData.push(detailedObj);
        }
      });

      setChoosenLineItems(updateChoosenLineData);

      const isClubThere = reversePayData[index].paymentReceiptDetails.filter(
        (x) =>
          x.paymentType == "Club" &&
          x.isReversed === false &&
          x.isReversible === true
      );
      setIsClubReversible(isClubThere.length ? "Y" : "N");
      setSelectedAgreements(selectedAgreementId);
      setSelectedInventories(selectedInvId);
      const updateAddedValue: any = [...[], ...individualRowTotal];
      updateAddedValue[index] = collectedTotalValue.toFixed(2).toString();
      setIndividualRowTotal(updateAddedValue);
    } else {
      colVal.splice(colVal.indexOf(index), 1);
      const iniVal: any = [...[], ...selectedChildCheckBox];
      iniVal[index].fill(false);
      const copyVal = [...reversePayData];
      copyVal.map((mappedValue) => {
        mappedValue.paymentReceiptDetails.map((detailedMap, mapIndex) => {
          if (detailedMap.isReversed === true) {
            iniVal[index][mapIndex] = true;
          }
        });
      });
      setSelectedChildCheckBox(iniVal);
      const updateAddedValue: any = [...[], ...individualRowTotal];
      updateAddedValue[index] = "0.00";
      setIndividualRowTotal(updateAddedValue);
      setIsClubReversible("N");
      setSelectedAgreements([]);
      setSelectedInventories([]);
      setChoosenLineItems([]);
    }
    setSelectedMasterCheckBox(colVal.sort());
  }; // SQ-9 This method will be collecting and store the data when master check box is clicked.

  const childCheckBoxHandle = (
    e: any,
    indexFromMasterGrid: any,
    childIndex: any,
    amtObtained,
    agreementID,
    inventoryId,
    paymentType,
    lineItemData
  ) => {
    if (e.target.checked === false) {
      if (paymentType == "Club") {
        setIsClubReversible("N");
      }
      if (agreementID) {
        const updateAgreementID = selectedAgreements.filter(
          (previousAgrID) => previousAgrID !== agreementID
        );
        console.log("after removing updateAgreementID", updateAgreementID);
        setSelectedAgreements(updateAgreementID);
      }
      if(inventoryId) {
        const updateInvID = selectedInventories.filter(
          (previousInvID) => previousInvID !== inventoryId
        );
        console.log("after removing updateInvID", updateInvID);
        setSelectedInventories(updateInvID);
      }
      const updateChoosenLineData = choosenLineItems.filter(
        (x) => x.paymentSource !== lineItemData.paymentSource
      );
      setChoosenLineItems(updateChoosenLineData);

      const colVal = [...[], ...selectedMasterCheckBox];
      colVal.splice(
        colVal.indexOf(indexFromMasterGrid),
        selectedMasterCheckBox.length
      );
      setSelectedMasterCheckBox(colVal.sort()); // Used to uncheck parent check box if checked

      const iniVal: any = [...[], ...selectedChildCheckBox];
      iniVal[indexFromMasterGrid][childIndex] = false;
      setSelectedChildCheckBox(iniVal); // Used to remove the child check box value - Individual

      const subtractValue =
        Number(individualRowTotal[indexFromMasterGrid]) - Number(amtObtained);
      const updateAddedValue: any = [...[], ...individualRowTotal];
      updateAddedValue[indexFromMasterGrid] = subtractValue
        .toFixed(2)
        .toString();
      setIndividualRowTotal(updateAddedValue);
    } else if (e.target.checked === true) {
      if (paymentType == "Club") {
        setIsClubReversible("Y");
      }
      const updateChoosenLineData: any = [...[], ...choosenLineItems];
      updateChoosenLineData.push(lineItemData);
      setChoosenLineItems(updateChoosenLineData);
      console.log("agreementID in if condition", agreementID);
      if (agreementID) {
        const updateAgreementID: any = [...[], ...selectedAgreements];
        updateAgreementID.push(agreementID);
        setSelectedAgreements(updateAgreementID);
      }
      if (inventoryId) {
        const updateInvID: any = [...[], ...selectedInventories];
        updateInvID.push(inventoryId);
        setSelectedInventories(updateInvID);
      }
      const iniVal: any = [...[], ...selectedChildCheckBox];
      iniVal[indexFromMasterGrid][childIndex] = true;
      setSelectedChildCheckBox(iniVal);
      const sumValue =
        Number(individualRowTotal[indexFromMasterGrid]) + Number(amtObtained);
      const updateAddedValue: any = [...[], ...individualRowTotal];
      updateAddedValue[indexFromMasterGrid] = sumValue.toFixed(2).toString();
      setIndividualRowTotal(updateAddedValue);

      const collectedIndex: any = [];
      iniVal.map((childArray, index) => {
        const booleanResult = childArray.every((element) => element === true);
        if (booleanResult) {
          collectedIndex.push(index);
        }
      });
      const updateMasterCheck = [...selectedMasterCheckBox];
      collectedIndex.map((value) => {
        updateMasterCheck[value] = value;
      });
      setSelectedMasterCheckBox(updateMasterCheck);
    }
  }; // SQ-10 This method will be collecting and store the data when child check box is clicked.

  const invokeDocumentAPI = async (receiptID: any) => {
    setLoadingModal({ ...loadingModal, document: true });
    try {
      const receiptResponse = await getDocument(receiptID, "RCP");
      if (receiptResponse.status === 200 && receiptResponse.data.fileList[0].fileObject !== null) {
        const COOSReceipt = receiptResponse.data.fileList.filter(
          (x) => x.docType === "COOS"
        );
        const PRCTReceipt = receiptResponse.data.fileList.filter(
          (x) => x.docType === "PRCT"
        );
        const EXTRFReceipt = receiptResponse.data.fileList.filter(
          (x) => x.docType === "EXTRF"
        );
        const collectedBlobURL: any = [];

        if (PRCTReceipt.length) {
          const type = getContentType(PRCTReceipt[0]?.fileName);
          collectedBlobURL.push({
            receiptPDF: URL.createObjectURL(
              b64toBlob(PRCTReceipt[0]?.fileObject, type)
            ),
          });
        }

        if (COOSReceipt.length) {
          const type = getContentType(COOSReceipt[0]?.fileName);
          collectedBlobURL.push({
            certificatePDF: URL.createObjectURL(
              b64toBlob(COOSReceipt[0]?.fileObject, type)
            ),
          });
        }
        if (EXTRFReceipt.length) {
          const type = getContentType(EXTRFReceipt[0]?.fileName);
          collectedBlobURL.push({
            extensionPDF: URL.createObjectURL(
              b64toBlob(EXTRFReceipt[0]?.fileObject, type)
            ),
          });
        }
        if (collectedBlobURL.length > 0) {
          setBlobURL(collectedBlobURL);
          setOpenReceipt(true);
        }
      } else if (receiptResponse.status === 400 || (receiptResponse.status === 200 &&
        receiptResponse.data.fileList[0].fileObject == null)) {
        setExceptionModal({ ...exceptionModal, badRequest: true });
      } else if (receiptResponse.status === 500) {
        setExceptionModal({ ...exceptionModal, serverError: true });
      }
    } catch {
      setExceptionModal({ ...exceptionModal, serverError: true });
    }
    setLoadingModal({ ...loadingModal, document: false });
  }; // SQ-11 This method will be collecting the previous receipt by invoking getDocument API

  const onReceiptClosed = () => {
    if (blobURL.length > 1) {
      const copyURLData = [...blobURL];
      copyURLData.shift();
      setBlobURL(copyURLData);
    } else {
      setOpenReceipt(false);
    }
  }; // SQ-12 This method will be invoked when close button in the receipt is clicked.

  const dynamicReceiptBinder = () => {
    return (
      <Grid item className={classes.paymentmodal}>
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.paymentmodal}`}
        >
          <iframe
            ref={documentFrame}
            frameBorder={0}
            width="100%"
            height="600px !important"
            src={`${
              blobURL[0]?.receiptPDF !== undefined
                ? blobURL[0]?.receiptPDF
                : blobURL[0]?.certificatePDF !== undefined
                ? blobURL[0]?.certificatePDF
                : blobURL[0]?.extensionPDF !== undefined
                ? blobURL[0]?.extensionPDF
                : ""
            }#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton
            variant="outlined"
            color="secondary"
            className={classes.me2}
            onClick={() => onReceiptClosed()}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => documentFrame.current?.contentWindow?.print()}
          >
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container className={dynamicViewClass}>
      {receiptDetailsException.serverError ||
      receiptDetailsException.badRequest ||
      receiptDetailsException.notEligible ? (
        <ReceiptDetailsException />
      ) : reversePayData !== undefined ? (
        <Grid item md={12} className={classes.mb100}>
          <Card className={`${classes.card} ${classes.w100}`}>
            <CardContent>
              <Grid item md={12}>
                <RACTable
                  renderTableHead={renderParentHeader}
                  renderTableContent={renderParentContent}
                  className={classes.agrementGridMC}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <LoaderGrid />
      )}

      <RACModalCard
        isOpen={openReceipt}
        className={classes.customModal}
        maxWidth="lg"
        title={
          blobURL[0]?.receiptPDF !== undefined
            ? "Receipt"
            : blobURL[0]?.certificatePDF !== undefined
            ? "Certificate of Ownership"
            : blobURL[0]?.extensionPDF !== undefined
            ? "Extension Form"
            : ""
        }
        // eslint-disable-next-line react/no-children-prop
        children={dynamicReceiptBinder()}
        closeIcon={true}
        onClose={() => onReceiptClosed()}
      />
    </Grid>
  );
}
