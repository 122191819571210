/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable prettier/prettier */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable sonarjs/cognitive-complexity */



/* eslint-disable no-debugger */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */


/* eslint-disable sonarjs/no-collapsible-if */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  makeStyles,
} from '@rentacenter/racstrap';
import { setSeconds } from 'date-fns';
import moment from 'moment';
import { useEffect, useState ,useContext } from 'react';
import CurrencyInput from "react-currency-input-field";
import { number } from 'yup';
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import { userTraceContextData } from "../../context/UserTraceContext";
import * as constants from '../../constants/constants';

export function CarryRent(props: any) {
  const {
    userTraceValue,
  } = useContext(userTraceContextData);
  const [userTraceData, setUserTraceData] = userTraceValue;
  const classes = takePaymentStyles();  
  const [carryRentTextValue,setcarryRentTextValue]=useState<any>(props.carryAmount)
 
 
   const resTotalAmount =(props.totalduetendertype).length==0?0.00:((props.totalduetendertype).map(bill => bill.Amount).reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2)));
   
   const [carryFreeze,setcarryFreeze]=useState<any>(0)
   useEffect(() => {
    if(Number(carryRentTextValue)==0||Number(totalCalci)>=Number(resTotalAmount)){
      setcarryFreeze(resTotalAmount)
     }
   },[resTotalAmount])
   
   let totalCalciText=0
   let ldwtax=0
   let ldwAmount=0
   let ldwtaxwithAmount=0
  //  let ldwTerminateToggle=0
  let reqAgreeArray=props.mxcarry.filter(
    (x: any) => !constants.FINAL_PAYMENT_AGREEMENT.includes(x.Nextduedate) && !constants.CARRY_RENT_NOT_ALLOWED_AGREEMENT_TYPE.includes(x.Agreementtype) && 
    x.number_of_paymentsmade!=0 && x.FreeTime==null && x.isZeroPayment !== true && x.isZeroPayment !== true    
  );
  
  for(let i=0;i<(reqAgreeArray).length;i++){
    let ldwTerminateToggle=0
    let coverageEndDateInfo:any=[]
       if(reqAgreeArray[i].agreementRateDetails!=undefined&&reqAgreeArray[i].agreementRateDetails!=null){
        let startDate = moment(reqAgreeArray[i].CurrentDueDate);
        let endDate = moment(reqAgreeArray[i].Nextduedate);
        let result = endDate.diff(startDate, 'days');
        if(Number(result)!=1){
          totalCalciText=totalCalciText+(Number((reqAgreeArray[i].agreementRateDetails.perDayRent))+Number((reqAgreeArray[i].agreementRateDetails.perDayRentTax)))
          for(let j=0;j<props.ldwChangeArr.length;j++)
          {
            
            let coverageEndDate=""
            // let policyStatus=""
            
         if(props.ldwChangeArr.length>0){
           // props.ldwChangeArr
            coverageEndDateInfo = props.ldwChangeArr.filter(
             (x) => String(x.agreementId) === String(reqAgreeArray[i].Agreementid)
           );
           if(coverageEndDateInfo.length>0){
             if(Number(coverageEndDateInfo[0].action)==2){
               coverageEndDate =  moment().format('MM/DD/YYYY');
               ldwTerminateToggle=1
             }else if(Number(coverageEndDateInfo[0].action)==1){
               coverageEndDate = "12/31/2099"
               ldwTerminateToggle=2
               if(Number(reqAgreeArray[i].agreementRateDetails.perDayLdw)==0){
                reqAgreeArray[i].agreementRateDetails.perDayLdw=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.weeklyRate)/7).toFixed(2)
                reqAgreeArray[i].agreementRateDetails.perDayLdwTax=((Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.weeklyRate)/7)*Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate)).toFixed(2)
                reqAgreeArray[i].agreementRateDetails.weeklyLdw=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.weeklyRate)).toFixed(2)
                reqAgreeArray[i].agreementRateDetails.weeklyLdwTax=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.weeklyRate)*Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate)).toFixed(2)
                reqAgreeArray[i].agreementRateDetails.biWeeklyLdw=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.biweeklyRate)).toFixed(2)
                reqAgreeArray[i].agreementRateDetails.biWeeklyLdwTax=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.biweeklyRate)*Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate)).toFixed(2)
                reqAgreeArray[i].agreementRateDetails.monthlyLdw=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.monthlyRate)).toFixed(2)
                reqAgreeArray[i].agreementRateDetails.monthlyLdwTax=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.monthlyRate)*Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate)).toFixed(2)
                reqAgreeArray[i].agreementRateDetails.semiMonthlyLdw=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.semimonthlyRate)).toFixed(2)
                reqAgreeArray[i].agreementRateDetails.semiMonthlyLdwTax=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.semimonthlyRate)*Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate)).toFixed(2)
              }
             }
           }
         }
       }
        //  }else if(props.mxcarry[i].Schedule=="Monthly"){
          // totalCalciText=totalCalciText+(Number((props.mxcarry[i].Amount))/31)
        //  }
        if(Number(reqAgreeArray[i].agreementRateDetails.perDayLdw)==0&&ldwTerminateToggle==2){
          // if(Number(props.adjustDueDateReq[i].agreementRateDetails.perDayLdw)==0&&ldwTerminateToggle==2){
            if(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.weeklyRate)>0){
              ldwtaxwithAmount=ldwtaxwithAmount+Number((Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.weeklyRate)/7).toFixed(2))
            }
          //  }else{
          //   ldwtax = ldwTerminateToggle==1?0:Number(props.adjustDueDateReq[i].agreementRateDetails.perDayLdwTax)
          //   ldwAmount = ldwTerminateToggle==1?0:Number(props.adjustDueDateReq[i].agreementRateDetails.perDayLdw)
          //   ldwtaxwithAmount=Number(ldwtax)+Number(ldwAmount)
          //  }
    
         }else{
          ldwtax = ldwTerminateToggle==1?0:Number(reqAgreeArray[i].agreementRateDetails.perDayLdwTax)
          ldwAmount = ldwTerminateToggle==1?0:Number(reqAgreeArray[i].agreementRateDetails.perDayLdw)
          ldwtaxwithAmount=ldwtaxwithAmount+Number(ldwtax)+Number(ldwAmount)
         }

        }

       
      }
  }

   
  totalCalciText=Number((Number(totalCalciText)+Number(ldwtaxwithAmount)).toFixed(2))
  const totalCalci:any=(Number(props.totalDue)-Number(totalCalciText)-Number(props.availableCreds) + Number(props.donationAmountAdd) + Number(props.totalDueSusAddAmount) - Number(props.totalDueSusUseAmount) + Number(props.totalDepositAmount) - Number(props.totalSurrenderAmount)).toFixed(2)
  // debugger
  if(Number(carryFreeze)==Number(resTotalAmount)){
  if(Number(props.remainingDue)==0&&Number(props.carryAmount)==0){
    totalCalciText=0.00
    if((Number(props.carryAmount)+Number(props.remainingDue)>0)){
    props.response({totalCalci:totalCalci,carryRentAmount:carryRentTextValue,maxCarry:Number(totalCalci)<=0?"0.00":Number(totalCalciText).toFixed(2)})
    }
  }else if(((Number(resTotalAmount))>Number(totalCalci))){
    let amount = carryRentTextValue
    totalCalciText = Number(Number(Number(props.remainingDue)+Number(props.carryAmount)).toFixed(2))
    if(Number(totalCalciText)<Number(carryRentTextValue)){
      amount ="0.00"
    }
    props.response({totalCalci:totalCalci,carryRentAmount:amount,maxCarry:Number(totalCalci)<=0?"0.00":Number(totalCalciText).toFixed(2)})
  }else if (Number(totalCalciText)>Number(carryRentTextValue)){
    props.response({totalCalci:totalCalci,carryRentAmount:carryRentTextValue,maxCarry:Number(totalCalci)<=0?"0.00":Number(totalCalciText).toFixed(2)})
  }
}else{
  totalCalciText=props.totalCalciTextValue
}
  

  const carryRentAmountOnchange =(Amount:any)=>{
   
    debugger;
    if(isNaN(Amount)==false){
      // if((String(Amount).split(".")[1].match(/.{1,1}/g)?.length) >2 ){

      // }
      // let extradecimals = false 
      const extrabool =  String(Amount).includes(".")?(String(Amount).split(".")[1].match(/.{1,1}/g)?.length):0
        if(Number(extrabool)>2){
          Amount= carryRentTextValue
          setcarryRentTextValue(carryRentTextValue)
        }
        // setcarryRentTextValue(Amount)
      
          setcarryRentTextValue(Amount)
          if(Amount==undefined ){
            setcarryRentTextValue('0.00')
          }
          let totalAmount =Amount
          if(Number(totalCalciText)<Number(Amount)||Number(totalCalciText)==0.00){
            totalAmount="0.00"
          }
          props.response({totalCalci:totalCalci,carryRentAmount:totalAmount,maxCarry:Number(totalCalci)<=0?"0.00":Number(totalCalciText).toFixed(2)})
       

    }else{
      setcarryRentTextValue(carryRentTextValue)
      // set
    }
    
   
  }
  const carryRentonBlur =(Amount:any)=>{
    setcarryRentTextValue(Number(carryRentTextValue).toFixed(2))
  }
  if(Number(carryFreeze)==Number(resTotalAmount)){
  if(((Number(props.remainingDue)==0&&Number(carryRentTextValue)>0)   
       &&(Number(props.carryAmount).toFixed(2)!=Number(carryRentTextValue).toFixed(2)))
      ) {
      // setcarryRentTextValue("0.00")
      props.response({totalCalci:totalCalci,carryRentAmount:"0.00",maxCarry:Number(totalCalci)<=0?"0.00":Number(totalCalciText).toFixed(2)})
  }
}
 
  
  return (
    <Grid container data-testid="availcreditid">
         <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
           <Card className={`${classes.card} ${classes.w100} ${classes.floatLeft} ${classes.p3}`} style={{ marginBottom: '90px' }}>
             <CardContent className={classes.p0}>
             <Grid  className={`${classes.w100} ${classes.floatLeft}`}>          
                    <>
                    <Typography component={'label'} className={`${classes.formLabel}  ${classes.mt3} ${classes.floatLeft}`}>Minimum Payment</Typography>
                    <Box component="span" className={`${classes.labeltxtstyle} ${classes.mt3} ${classes.floatRight} ${classes.textend} `}>$<Box className={`${classes.displayInline}`} >{Number(totalCalci)<0?"0.00":totalCalci}</Box></Box>
                    </>
              </Grid>
              <Grid  className={`${classes.w100} ${classes.floatLeft}`}>          
                    <>
                    <Typography component={'label'} className={`${classes.formLabel}  ${classes.mt3} ${classes.floatLeft}`}>Max. Carry Amount</Typography>
                    <Box component="span" className={`${classes.labeltxtstyle} ${classes.mt3} ${classes.floatRight} ${classes.textend} `}>$<Box className={`${classes.displayInline}`} >{Number(totalCalci)<=0?"0.00":Number(totalCalciText).toFixed(2)}</Box></Box>
                    </>
              </Grid>
                   <Grid  className={`${classes.w100} ${classes.floatLeft}`}>
                     <>
                     <Typography component={'label'} className={`${classes.formLabel} ${classes.w35} ${classes.mt3} ${classes.floatLeft}`}>Carry Amount</Typography>
                     {/* <Box component="span" className={`${classes.labeltxtstyle} ${classes.mt3} ${classes.floatLeft} ${classes.accountBalanceCss}`}>$<Box className={classes.displayInline}>{totalCalciText}</Box></Box> */}
                     </>
                     <Grid item className={`${classes.w50} ${classes.floatRight} ${classes.mt2}`}>
                         <Grid item className={`${classes.inputgroup} ${classes.mb3}`}>
                           <span className={classes.inputgrouptext}>$</span>
                           <CurrencyInput
                             className={`${classes.formcontrol3} ${classes.textend}`}
                             value={carryRentTextValue}
                             type="text"
                            //  disabled={Number(props.remainingDue)==0&&Number(props.carryAmount)==0||Number(totalCalci)<=0}
                             id="carryRent"
                             defaultValue={carryRentTextValue}
                             decimalsLimit={2}
                             disabled={Number(carryFreeze)!=Number(resTotalAmount)&&Number(carryRentTextValue)!=0}
                            //  onChange={(e)=>{setcarryRentTextValue(Number(e.target.value).toFixed(2))}}
                            onChange={(e)=>{carryRentAmountOnchange(e.target.value)}}
                            onBlur={(e)=>{carryRentonBlur(e.target.value)}}
                           />
                         </Grid>
                         {Number(carryRentTextValue)>(Number(totalCalciText))?
                         
                          <Typography
                          component={"label"}
                          
                          className={classes.mandatoryfield}
                        >
                          Amount should not be entered more than the Max.CarryAmount
                        </Typography>:null}
                        
                     </Grid>
                   </Grid>
             </CardContent>
           </Card>
   
         </Grid>
       </Grid>
    //    available credits ends here
  );
}


