/* eslint-disable */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-constant-condition */
/* eslint-disable use-isnan */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable use-isnan */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console*/
import React, { useState, useEffect, useContext } from "react";
import {
  RACTextbox,
  RACDatePicker,
  RACSelect,
  RACTableCell,
  RACTable,
  RACTableRow,
  RACButton,
  RACModalCard,
  RACCheckBox,
  Card,
  Grid,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  List,
  ListItem,
  RACTooltip,
} from "@rentacenter/racstrap";
import CreateCustomerComponent from "./CreateCustomer";

import { ReactComponent as SortDownIcon } from "../../assets/images/sort-down-icon.svg";
import { ReactComponent as SortUpIcon } from "../../assets/images/sort-up-icon.svg";
import { ReactComponent as DEConditionallyApproved } from "../../assets/images/DE-VerificationRequired.svg";
import { ReactComponent as DEApproved } from "../../assets/images/DE-Approved.svg";
import { ReactComponent as Alerticon } from "../../assets/images/No-records.svg";
import { ReactComponent as DEDeclined } from "../../assets/images/DE-Declined.svg";
import { ReactComponent as SearchImageDefault } from "../../assets/images/search-btn.svg";
// import { ReactComponent as AccordianOpenIcon } from '../../assets/images/Accordion-down.svg';
// import { ReactComponent as AccordianCloseIcon } from '../../assets/images/Accordion-close.svg';
import AccordianOpenIcon from "../../assets/images/opened-accordian.png";
import AccordianCloseIcon from "../../assets/images/closed-accordian.png";
import { ReactComponent as CrossIcon } from "../../assets/images/CrossIcon.svg";
import { ContainerContext } from "../../app/App";
import { CustomPropInterface } from "../../index";
// import accordianOpenIcon from '../assets/images/Accordion-down.svg';
// import accordianCloseIcon from '../assets/images/Accordion-close.svg';
import { DeliveryContext } from "../../context/DeliveryContext";
import moment from "moment";
import { useHistory } from "react-router";
import { API_ERROR_MESSAGE, DE_APPROVAL_FLOW } from "../../constants/constants";
//import { AddCoCustomer } from '../manageCustomer/AddCoCustomer';

// import {
//   agreementGridHeader,
//   SearchGridHeader,
// } from '../../constants/constants';
import {
  GetSearchResult,
  GetAgreementById,
  GetCustomerBasicInfo,
  GetNODEReason,
  RunDE,
  UpdateNoDeReason,
  getmenuandstore,
  CoworkerRequired,
  getRetentionOffers,
  postAgreementActivity,
  GetCustomerInfoOptions,
} from "../../api/user";
//import { parseInt } from 'lodash';
export interface sortColumns {
  columnId: string;
  sortOrder: string;
  IsSorted: boolean;
  IsSortable: boolean;
}
export interface dropdown {
  label: string;
  value: any;
}

import Modal from "react-modal";
import { ManageCustomerStyles } from "../../stylesJS/ManageCustomerStyles";
import { customerStyles } from "../../stylesJS/customerStyles";
import clsx from "clsx";
import { CustomerdataContext } from "../../context/customer/customerdata";
import { UserStateContext } from "../../context/user/user-contexts";
import SecondFactor from "../microfrontends/TwoFactorPopup";
import { ReactComponent as RetentionIcon } from "./../../assets/images/Retention.svg";
import { ReactComponent as ExchangeIcon } from './../../assets/images/Exchange.svg';
import RACExchange from "../microfrontends/RACExchange";
import { validateForExchange } from "../microfrontends/customer validation/ExchangeUtils";
import { agreementContext } from "../../refactoredComponents/context/PaymentInformationContext";
import { useQueryClient } from "@tanstack/react-query";
import CONSTANTS from "../../refactoredComponents/constants/constant";
// eslint-disable sonarjs/cognitive-complexity
export default function SearchResultsContext(props: any) {
  const queryClient = useQueryClient();
  const { setAgreementDetailsDeepClone, agreementDetailsDeepClone, setAgreementDetails, setCustomerInfo, setCustomerInfoDeepClone, setOriginPopup } = useContext(agreementContext);
  const UserStateContextData = useContext(UserStateContext);
  const [canEnableGoogleValdation, setCanEnableGoogleValdation] = useState(false);
  const classesManageCustomerStyles = ManageCustomerStyles();
  const classes = customerStyles();
  const [blurLoaderActive, setblurLoaderActive] = useState<any>(false);
  const [postnodeReason, setpostnodeReason] = useState("");
  const [sortOrder, setSortOrder] = useState<any>({ fN: "asc", lN: "asc" });
  const [showCustomerAgrIndex, setshowCustomerAgrIndex] = useState<any>(undefined);
  const [EnableLoaderButton, setEnbleLoaderButton] = useState(false);
  const [newCustValidBtnHidden, setnewCustValidBtnHidden] = useState<any>(true);
  const [noDEReasonDD, setnoDEReasonDD] = useState<any>([]);
  const [disableSearchBtn, setdisableSearchBtn] = useState(true);

  const initialState = {
    FirstName: "",
    LastName: "",
    Dob: "",
    Ssn1: "",
    Ssn2: "",
    Ssn3: "",
    Phonenumber: "",
    agreementNumber: "",
    addressLine1: "",
    addressLine2: "",
    zip: "",
    city: "",
    state: "0"
  };

  const [searchData, setSearchData] = useState(initialState);
  const [initialNoRecPopUp, setinitialNoRecPopUp] = useState<any>(true);
  const [enterssnisopen, setenterssnisopen] = React.useState(false);
  const [DEStatus, setDEStatus] = useState<any>("Decline");
  // const [downArrow, setdownArrow] = useState<any>([]);
  const [searchResults, setsearchResults] = useState<any>([]);
  const [agreements, setagreements] = useState<any>([]);
  const [checkBoxActive, setcheckBoxActive] = useState<any>(undefined);
  const [custID, setcustID] = useState<any>(undefined);
  const dt = new Date();
  const loginStore = sessionStorage.getItem("storeNumber");
  const [intialLoad, setintialLoad] = useState<boolean>(true);
  const [isclsAlertMsg, setisclsAlertMsg] = useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [liketoCreateAgreement, setliketoCreateAgreement] =
  //   useState<any>(false);
  const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);
  const [isUnableTofetchData, setisUnableTofetchData] = useState<any>(false);
  const [dedeclineisopen, setdedeclineisopen] = React.useState(false);
  const [reasonDEopen, setreasonDEopen] = React.useState(false);
  const [comingSoonPopup, setcomingSoonPopup] = useState<boolean>(false);
  const [agreementMandatoryFieldpopup, setagreementMandatoryFieldpopup] =
    useState<boolean>(false);
  const [coWorkerRole, setcoWorkerRole] = useState<any>();
  const history = useHistory();
  const dateFormat = "ddd, MM/DD/YYYY";
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const {
    paymentSearchValue,
    // searchResult
  } = useContext(DeliveryContext);
  const [searchVal, setsearchVal] = paymentSearchValue;
  const { paymentFunctionFlag, setpaymentFunctionFlag, isExchangeEnabled, setExchangeEnabled, exchangeNavigationPopup, setExchangeNavigationPopup, setExchangePeriod, setReturnPeriod, exchangePeriod, returnPeriod } =
    useContext(CustomerdataContext);
  const [createcustomerPopup, setcreatecustomerPopup] = useState<any>(false);
  const [agrDetails, setagrDetails] = useState<any>();

  // retention changes start
  const [isRetentionEnabled, setIsRetentionEnabled] = useState<boolean>(false);
  const [openSLA, setOpenSLA] = useState<boolean>(false);
  const [openOfferLoading, setOpenOfferLoading] = useState<boolean>(false);
  const [choosenAgreementNumber, setChoosenAgreementNumber] = useState<any>();
  const [choosenAgreementID, setChoosenAgreementID] = useState<any>();
  const [availableRetentionOffers, setAvailableOffers] = useState<any>();
  const [offerResponse, setOfferResponse] = useState<any>();
  const [openRetention, setOpenRetention] = useState<boolean>(false);
  const [currentRole, setCurrentRole] = useState<any>();
  const [choosenOptionIndex, setChoosenIndex] = useState<any>();
  const [choosenRetentionOptions, setChoosenRetentionOptions] = useState<any>();
  const [choosenOfferReason, setChoosenOfferReason] = useState<string>();
  const [retentionOptions, setRetentionOptions] = useState<any>();
  const [openReinstateAlert, setOpenReinstateAlert] = useState<boolean>(false);
  const [openActivityLoading, setOpenActivityLoading] =
    useState<boolean>(false);
    const [rentPaid, setRentPaid] = useState<any>(0);//RAC2
    const [exchangeSelected, setExchangeSelected] = useState(false);//RAC2
    const [isExchangeFuncEnabled, setIsExchangeFuncEnabled] = useState(false);
    const [exchangeAgreementNumber, setExchangeAgreementNumber] = useState('');
    const [suspenseAmount, setSuspenseAmount] = useState(0);
  // const { customerDetails } = useCustomerDetails();
  // const { customerId } = customerDetails || {};
  const [customerId, setCustomerId] = useState<any>({});
  const availableOffers = [
    "Product",
    "No Longer Needs",
    "EPO too high",
    "Renewal rate too high",
    "Cannot make current payment",
    "Service",
    "Moving",
    "Total RTO/Term too high",
    "SAC too high",
  ];
  // retention changes end
  /**
   * To clear the context in refactor code
   */
  const [isRefactor, setIsRefactor] = useState<boolean>(false);
  console.log('agreementDetailsDeepClone', agreementDetailsDeepClone);
  const [states, setStates] = useState<dropdown[]>([{ label: 'Select', value: '0' }]);
  const [dropDownLoading, setDropDownLoading] = useState<boolean>(false);

  useEffect(()=>{
    if (isRefactor){
      queryClient.clear();
      setAgreementDetails(CONSTANTS.AGREEMENT_DETAILS_INITIAL_ARRAY);
      setAgreementDetailsDeepClone([]);
      setCustomerInfo(CONSTANTS.CUSTOMER_INITIAL_OBJECT);
      setCustomerInfoDeepClone(CONSTANTS.CUSTOMER_INITIAL_OBJECT);
      setOriginPopup(true)
    }
  },[isRefactor]);

  // const [searchContextResult, setsearchContextResult] = searchResult;
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    // if (props.location.state && intialLoad === true) {
    //   /* eslint-disable no-console */
    //   console.log('props.location.state', props.location.state);
    //   //setFirstNameVal(props);
    //   setSeachVal(props);
    //   setintialLoad(false);
    // }

    configPaymentFunctions();

    if (!searchVal.cancelClick) {
      contextPayload();
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const getCoWorkerRole = async () => {
      try {
        if (containerData !== undefined) {
          console.log("containerData", containerData);
          console.log("containerDataRole", containerData.GetRole());
          const roleObj = containerData.GetRole();
          setcoWorkerRole(
            roleObj == "SM" ||
              roleObj == "CAR" ||
              roleObj == "AM" ||
              roleObj == "LAM" ||
              roleObj == "DMT"
              ? 1
              : roleObj == "DM" || roleObj == "RD"
              ? 2
              : 0
          );
          setCurrentRole(roleObj);
        } else {
          const coWorkerRoleObj: any = await getmenuandstore();
          if (
            coWorkerRoleObj?.coworkerProfile !== undefined &&
            coWorkerRoleObj?.coworkerProfile !== null &&
            coWorkerRoleObj?.coworkerProfile.role !== undefined &&
            coWorkerRoleObj?.coworkerProfile.role !== null
          ) {
            const roleObj = coWorkerRoleObj?.coworkerProfile.role;
            // setcoWorkerRole(roleObj);
            setCurrentRole(roleObj);
            setcoWorkerRole(
              roleObj == "SM" ||
                roleObj == "CAR" ||
                roleObj == "AM" ||
                roleObj == "LAM" ||
                roleObj == "DMT"
                ? 1
                : roleObj == "DM" || roleObj == "RD"
                ? 2
                : 0
            );
          }
        }
      } catch (e: any) {
        console.log("Error in co worker role");
      }
    };
    setinitialNoRecPopUp(true);
    console.log("initial rendering");
    //GetNoDEReason();
    getCoWorkerRole();
    fetchDropDowns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const collectedRetentionOptions: any = [];
    let agreementData: any = [];
    if (offerResponse) {
      const filteredReason: any = offerResponse?.returnReasons.filter(
        (el: any) => el.reason == choosenOfferReason
      );
      offerResponse?.agreementOffers.forEach((offer: any) => {
        offer.updateDateTime = new Date(offer.updateDateTime);
      });
      agreementData = offerResponse?.agreementOffers.sort(
        (a: any, b: any) => b.updateDateTime - a.updateDateTime
      );

      if (agreementData[0]?.exchangeOfferDescription && !isExchangeFuncEnabled) {
        collectedRetentionOptions.push({
          displayLabel: agreementData[0]?.exchangeOfferDescription,
          offerValue: {
            redirectionPage: 'Inventory',
            functionalityInPage: 'AGREXC',
            description: agreementData[0].exchangeOfferDescription,
            agreementExchangeAmount: agreementData[0]?.exchangeAmount,
          },
        });
      }

      if (choosenOfferReason == "Product" || choosenOfferReason == "Service") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Repair or switch out product",
            offerValue: {
              redirectionPage: "Agreement",
              functionalityInPage: "SW",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else if (choosenOfferReason == "No Longer Needs") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Explain lifetime reinstatement to the customer",
            offerValue: {
              redirectionPage: "Agreement",
              functionalityInPage: "R",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else if (choosenOfferReason == "Moving") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Give customers their local RAC store info",
            offerValue: {
              redirectionPage: "StoreManagement",
              functionalityInPage: "MOVE",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else if (choosenOfferReason == 'RAC Exchange') {//RAC2
        collectedRetentionOptions.push(
          {
            displayLabel: agreementData[0]?.exchangeOfferDescription,
            offerValue: {
              redirectionPage: 'Inventory',
              functionalityInPage: 'AGMXNGS',
              description: 'Agreement Exchange Selected',
            }
          },
          {
            displayLabel: "Customer does not accept any offer"
          }
        );
      } else {
        // let alteredReasonDescription: any = '';
        // if (choosenOfferReason == 'Early Purchase Option (EPO) too high') {
        //   alteredReasonDescription = 'EPO too High';
        // } else if (choosenOfferReason == 'Same as Cash (SAC) too high') {
        //   alteredReasonDescription = 'SAC too High';
        // } else if (choosenOfferReason == 'Term Rent to own (TRTO) too high') {
        //   alteredReasonDescription = 'Total RTO/Term too high';
        // } else {
        //   alteredReasonDescription = choosenOfferReason;
        // }

        filteredReason.map((el: any) => {
          if (el.epoEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].epoOfferDescription,
              offerValue: {
                redirectionPage: "Payment",
                functionalityInPage: "EPO",
                currentEpo: agreementData[0].currentEpo,
                epoDiscount: agreementData[0].epoDiscount,
                newEpo: agreementData[0].newEpo,
              },
            });
          }
          if (el.rateReductionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].rateOfferDescription,
              offerValue: {
                redirectionPage: "Agreement",
                functionalityInPage: "RR",
                currentRate: agreementData[0].currentRate,
                rateReduction: agreementData[0].rateReduction,
                newRate: agreementData[0].newRate,
              },
            });
          }
          if (el.termReductionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].termOfferReduction,
              offerValue: {
                redirectionPage: "Agreement",
                functionalityInPage: "TR",
                currentRemainingTerm: agreementData[0].currentRemainingTerm,
                termReduction: agreementData[0].termReduction,
                newRemainingTerm: agreementData[0].newRemainingTerm,
              },
            });
          }
          if (el.extensionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].extensionOfferDescription,
              offerValue: {
                redirectionPage: "Payment",
                functionalityInPage: "FREETIME",
                extension: agreementData[0].extension,
              },
            });
          }
        });
        if (collectedRetentionOptions.length) {
          collectedRetentionOptions.push({
            displayLabel: "Customer does not accept any offer",
          });
        } else {
          collectedRetentionOptions.push({
            displayLabel: "No retention offer option available",
          });
        }
      }
    } else {
      collectedRetentionOptions.push({
        displayLabel: "No retention offer option available",
      });
    }
    setRetentionOptions(collectedRetentionOptions);
  }, [choosenOfferReason]);

  const configPaymentFunctions = async () => {
    const bodyobject = {
      storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
      paramKeyNames: [
        "PaymentFunctions",
        "CashSale",
        "PaymentFee",
        "ReversePayment",
        "PaymentSearchCustomerRetention",
        "AgreementExchange",
        "AgreementExchangePeriod",
        "AgreementExchangeReturnPeriod",
        'EnableGoogleAddressValidation',
        'RACExchange',
        'PaymentRefactorFlag'
      ],
    };
    const storeNumber = window.sessionStorage.getItem("storeNumber");
    if (storeNumber && storeNumber != null && storeNumber != undefined && storeNumber != '' && storeNumber != "undefined" && storeNumber != "null"  ) {
      const CoworkerNum = await CoworkerRequired(bodyobject);
      if (
        CoworkerNum !== undefined ||
        (null &&
          CoworkerNum.storeProfileResponse !== undefined &&
          CoworkerNum.storeProfileResponse &&
          CoworkerNum.storeProfileResponse.configDetails !== undefined &&
          CoworkerNum.storeProfileResponse.configDetails.length > 0 &&
          CoworkerNum.storeProfileResponse.configDetails[0].configDetails !==
          undefined &&
          CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length >
          0)
      ) {
        let payFuncFlag = 0;
        let isCashSaleFlag = 0;
        let configConvFeeWithOutTax = 0;
        let reversePaymentFlag = 0;
        let paymentRefactor = 0;
        for (
          let i = 0;
          i <
          CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length;
          i++
        ) {
          const configArray =
            CoworkerNum.storeProfileResponse.configDetails[0].configDetails;

          if (configArray[i].paramKeyName == "PaymentFunctions") {
            payFuncFlag = Number(configArray[i].paramValue);
          }
          if (configArray[i].paramKeyName == "CashSale") {
            isCashSaleFlag = Number(configArray[i].paramValue);
          }
          if (configArray[i].paramKeyName == "PaymentFee") {
            configConvFeeWithOutTax = Number(configArray[i].paramValue);
          }
          if (configArray[i].paramKeyName == "ReversePayment") {
            reversePaymentFlag = Number(configArray[i].paramValue);
          }
          if (configArray[i].paramKeyName == "PaymentSearchCustomerRetention") {
            setIsRetentionEnabled(
              Number(configArray[i].paramValue) > 0 ? true : false
            );
          }
          if (configArray[i].paramKeyName == "AgreementExchange") {
            setExchangeEnabled(
              Number(configArray[i].paramValue) > 0 ? true : false
            );
          }
          if (configArray[i].paramKeyName == "AgreementExchangePeriod") {
            setExchangePeriod(
              configArray[i].paramValue ? configArray[i].paramValue : ''
            );
          }
          if (configArray[i].paramKeyName == "AgreementExchangeReturnPeriod") { 
            setReturnPeriod(
              configArray[i].paramValue ? configArray[i].paramValue : ''
            );
          }
          if (configArray[i].paramKeyName == "RACExchange") { 
            setIsExchangeFuncEnabled(configArray[i].paramValue == '1' ? true : false);
          }
          (configArray[i].paramKeyName === 'EnableGoogleAddressValidation' && configArray[i].paramValue == '1' ) && setCanEnableGoogleValdation(true)

          if( configArray[i].paramKeyName == "PaymentRefactorFlag"){
            paymentRefactor = configArray[i].paramValue
            configArray[i].paramValue == '1' && setIsRefactor(true);
          }
          setpaymentFunctionFlag({
            payFunc: payFuncFlag,
            isCashSale: isCashSaleFlag,
            convFeeWithOutTax: configConvFeeWithOutTax,
            reversePayment: reversePaymentFlag,
            paymentRefactor: paymentRefactor,
          });
        }
      }
    }
  };
  const checkPermissionForCusRed = () => {
    const currentUser: any = UserStateContextData;
    console.log(`outside if agreement`, currentUser);
    return (
      (!currentUser.error &&
        currentUser?.user?.permissions?.NAV_MENU.length > 0 &&
        currentUser?.user?.permissions?.NAV_MENU.includes("RPDPAYMENT")) ||
      currentUser?.user?.permissions?.NAV_MENU.includes("RPDPAY")
    );
    //  {
    // eslint-disable-next-line no-console
    // console.log(`inside agreement if condition`, customerId)
    // history.push(`/customer/update/${customerId}/customer`);
    //   return true
    // }else{
    //   return false
    // }
  };
  const checkPermissionForAgrRed = () => {
    // if (customerId && agreementId) {
    const currentUser: any = UserStateContextData;
    // eslint-disable-next-line no-console
    console.log(`outside if agreement`, currentUser);
    return (
      !currentUser.error &&
      currentUser?.user?.permissions?.NAV_MENU.length > 0 &&
      currentUser?.user?.permissions?.NAV_MENU.includes("RPDAGR")
    );
    //  {
    //   return true;
    // }else {
    // return false
  };

  const checkPermissionForPaymentRed = () => {
    // if (customerId && agreementId) {
    const currentUser: any = UserStateContextData;
    // eslint-disable-next-line no-console
    console.log(`outside if agreement`, currentUser);
    return (
      !currentUser.error &&
      currentUser?.user?.permissions?.NAV_MENU.length > 0 &&
      currentUser?.user?.permissions?.NAV_MENU.includes("RPDPAYMENT")
    );
    //  {
    //   return true;
    // }else {
    // return false
  };

  const handleChange = (name: any, value: any) => {
    setSearchData((prevState: any) => ({
      ...prevState,
      [name]: value
    }));
  };

  const contextPayload = async () => {
    console.log("sufi", searchVal);
    const { firstName, lastName, dob, taxId, phones, agreementNumber, addresses } = searchVal;
    const phoneList = [{ phoneNumber: "" }];
    const payloadReq: any = {};

    const updatePayload = (key: string, value: any, handleChangeKey?: string) => {
      if (value) {
        payloadReq[key] = value;
        if (handleChangeKey) handleChange(handleChangeKey, value);
      }
    };

    if (firstName) {
      updatePayload("firstName", firstName, "FirstName");
    };

    if (lastName) {
      updatePayload("lastName", lastName, "LastName");
    };

    if (dob) {
      const formattedDob = moment(dob).format("YYYY-MM-DD");
      updatePayload("dateOfBirth", formattedDob, "Dob");
    };

    if (taxId) {
      payloadReq.taxId = taxId;
      handleChange("Ssn1", taxId.slice(0, 3));
      handleChange("Ssn2", taxId.slice(3, 5));
      handleChange("Ssn3", taxId.slice(5));
    };

    if (phones && phones.length > 0 && phones[0]?.phoneNumber !== '') {
      const cleanedPhone = phones[0].phoneNumber.replace(/\D/g, "");
      phoneList[0].phoneNumber = cleanedPhone;
      payloadReq.phones = phoneList;
      const formattedPhone = cleanedPhone.replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, "($1) $2-$3");
      handleChange("Phonenumber", formattedPhone);
    };

    if (agreementNumber) {
      updatePayload("agreementNumber", agreementNumber, "agreementNumber");
    }

    if (addresses && addresses.length > 0) {

      const { addressLine1, addressLine2, postalCode, city, state } = addresses[0];
      if (addressLine1 !== '') {
        handleChange("addressLine1", addressLine1);
      }

      if (addressLine2) {
        handleChange("addressLine2", addressLine2);
      }

      if (postalCode) {
        handleChange("zip", postalCode);
      }

      if (city) {
        handleChange("city", city);
      }

      if (state !== '') {
        handleChange("state", state);
      }

      if (addressLine1 || addressLine2 || postalCode || city || state) {
        payloadReq.addresses = addresses;
      }
    }

    if (Object.keys(payloadReq).length > 0) {
      await getSearchValues(payloadReq);
    } else {
      setinitialNoRecPopUp(true);
    }
  };

  const getFormatedStatesDropDown = (statesDropDown: any, location?: any) => {
    const states = statesDropDown.map((data: any) => ({
      label: data.abbreviation,
      value: location ? data.stateProvinceId : data.abbreviation,
    }));

    const sortedStates = states.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return [{ label: 'Select', value: '0' }, ...sortedStates];
  };

  const fetchDropDowns = async () => {
    try {
      setDropDownLoading(true);
      const dropDownResponse = await GetCustomerInfoOptions('0');
      const statesDropDown = dropDownResponse?.data?.stateList?.getStateResponse;
      if (statesDropDown?.length > 0) {
        const formattedStates: dropdown[] = getFormatedStatesDropDown(statesDropDown);
        setStates(formattedStates);
      }
    } catch (e: any) {
      console.error(e);
    } finally {
      setDropDownLoading(false);
    }
  };

  useEffect(() => {
    const {
      FirstName = '',
      LastName = '',
      Dob = '',
      Ssn1 = '',
      Ssn2 = '',
      Ssn3 = '',
      Phonenumber = '',
      addressLine1 = '',
      addressLine2 = '',
      zip = '',
      city = '',
      state = '',
      agreementNumber = '',
    } = searchData;

    const ssnLength = Ssn1.length + Ssn2.length + Ssn3.length;
    const phoneLength = Phonenumber.length;
    const nameLength = FirstName.length + LastName.length;

    const disableSearch = () => setdisableSearchBtn(true);
    const enableSearch = () => setdisableSearchBtn(false);

    if (!nameLength && !Dob && !ssnLength && !phoneLength) {
      disableSearch();
    }

    if (ssnLength && !phoneLength) {
      checkSSn();
    } else if (phoneLength && !ssnLength) {
      checkPhone();
    } else if (ssnLength && phoneLength === 14) {
      checkSSn();
    } else if (phoneLength && ssnLength === 9) {
      checkPhone();
    }


    setnewCustValidBtnHidden(checkBoxActive === undefined);

    const checkField = (condition: any) => {
      if (condition) {
        enableSearch();
      }
    };

    checkField(nameLength >= 3 && !phoneLength && !ssnLength);
    checkField(nameLength < 3 && phoneLength === 14 && !ssnLength && Dob);
    checkField(nameLength < 3 && !phoneLength && ssnLength === 9 && Dob);
    checkField(nameLength < 3 && !phoneLength && !ssnLength && Dob);
    checkField((nameLength < 3 && phoneLength === 14) || (ssnLength === 9 && Dob));
    checkField(Dob && !phoneLength && !ssnLength);
    checkField(Dob && phoneLength >= 14 && !ssnLength);
    checkField(Dob && !phoneLength && ssnLength === 9);
    checkField(addressLine1.trim().length >= 5 || agreementNumber.trim().length >= 4);
    checkField(addressLine2.trim().length >= 5 || city.trim().length >= 3 || state.length > 1 || zip.length >= 5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchData.FirstName,
    searchData.LastName,
    searchData.Dob,
    searchData.Ssn1,
    searchData.Ssn2,
    searchData.Ssn3,
    searchData.Phonenumber,
    searchData.addressLine1,
    searchData.addressLine2,
    searchData.zip,
    searchData.state,
    searchData.city,
    searchData.agreementNumber,
    checkBoxActive,
    isclsAlertMsg,
  ]);

  const checkPhone = () => {
    if (searchData.Phonenumber.length >= 14) {
      setdisableSearchBtn(false);
    } else {
      setdisableSearchBtn(true);
    }
  };
  const checkSSn = () => {
    if (searchData.Ssn1?.length + searchData.Ssn2?.length + searchData.Ssn3?.length == 9) {
      setdisableSearchBtn(false);
    } else {
      setdisableSearchBtn(true);
    }
  };

  const GetNoDEReason = async () => {
    const res: any = await GetNODEReason();
    // eslint-disable-next-line no-console
    console.log(res);
    if (res !== undefined && res.referenceDetails !== undefined) {
      const sortedlist = res.referenceDetails.sort((a: any, b: any) =>
        a["description"] < b["description"] ? -1 : 1
      );
      const referenceDetailsObj: any = [];
      sortedlist.map((value: any, index: any) => {
        if (index === 0) {
          referenceDetailsObj.push({
            label: "Select",
            value: "0",
          });
        }
        referenceDetailsObj.push({
          label: value.description,
          value: value.referenceCode,
        });
      });
      // const stateDDStructure = sortedlist.map((el: any, i: any) => {
      //   if(i== 0){

      //   }
      //   res.referenceDetails[i].label = el.description;
      //   res.referenceDetails[i].value = el.referenceCode;
      //   return el;
      // });
      setnoDEReasonDD(referenceDetailsObj);
      setDropDownLoaded(true);
    }
  };

  useEffect(() => {
    /*  { pathname: '/customer', state: { FirstName: firstname, LastName: lastname, DOB: dob, PhoneNumber: phonenumber, SSN1: ssn1, SSN2: ssn2, SSN3: ssn3 } }*/
    // grid();
    console.log("useEffect getting trigger");
    console.log("second time rendering");
    showSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  // const setFirstNameVal = (props: any) => {
  //   if (props.location.state.FirstName !== undefined) {
  //     setFirstName(props.location.state.FirstName);
  //     //payload.firstName = props.location.state.FirstName;
  //   }
  // };
  // const setSeachVal = async (props: any) => {
  //   const phones: any = [
  //     {
  //       phoneNumber: '',
  //     },
  //   ];
  //   const payload: any = {};
  //   if (
  //     props.location.state.FirstName !== undefined &&
  //     props.location.state.FirstName !== ''
  //   ) {
  //     setFirstName(props.location.state.FirstName);
  //     payload.firstName = props.location.state.FirstName;
  //   }
  //   if (
  //     props.location.state.LastName !== undefined &&
  //     props.location.state.LastName !== ''
  //   ) {
  //     setLastName(props.location.state.LastName);
  //     payload.lastName = props.location.state.LastName;
  //   }
  //   if (
  //     props.location.state.DOB !== undefined &&
  //     props.location.state.DOB !== ''
  //   ) {
  //     SetDob(props.location.state.DOB);
  //     payload.dateOfBirth = props.location.state.DOB;
  //   }
  //   if (
  //     props.location.state.PhoneNumber !== undefined &&
  //     props.location.state.PhoneNumber !== ''
  //   ) {
  //     setPhonenumber(
  //       props.location.state.PhoneNumber.replace(
  //         /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  //         // eslint-disable-next-line sonarjs/no-duplicate-string
  //         '($1) $2-$3'
  //       )
  //     );
  //     phones[0].phoneNumber = props.location.state.PhoneNumber;
  //     payload.phones = phones;
  //   }
  //   if (
  //     props.location.state.SSN1 !== undefined &&
  //     props.location.state.SSN1 !== '' &&
  //     props.location.state.SSN2 !== undefined &&
  //     props.location.state.SSN2 !== '' &&
  //     props.location.state.SSN3 !== undefined &&
  //     props.location.state.SSN3 !== ''
  //   ) {
  //     payload.taxId =
  //       props.location.state.SSN1 +
  //       props.location.state.SSN2 +
  //       props.location.state.SSN3;
  //   }
  //   if (
  //     props.location.state.SSN1.length == 3 &&
  //     props.location.state.SSN2.length == 2 &&
  //     props.location.state.SSN3.length == 4
  //   ) {
  //     setssn1(props.location.state.SSN1);
  //     setssn2(props.location.state.SSN2);
  //     setssn3(props.location.state.SSN3);
  //   }

  //   // eslint-disable-next-line no-console
  //   console.log('payloadRequest', payload);
  //   if (Object.keys(payload).length > 0) {
  //     setEnbleLoaderButton(true);
  //     await getSearchValues(payload);
  //   } else {
  //     setinitialNoRecPopUp(true);
  //   }
  // };
  const getSearchValues = async (payload: any) => {
    // eslint-disable-next-line no-console
    console.log("payload", payload);
    // setinitialNoRecPopUp(false);
    // setEnbleLoaderButton(true);
    // setcheckBoxActive(undefined);
    // setshowCustomerAgrIndex(undefined);
    setblurLoaderActive(true);
    setinitialNoRecPopUp(false);
    setEnbleLoaderButton(true);
    setcheckBoxActive(undefined);
    setshowCustomerAgrIndex(undefined);

    try {
      const res = await GetSearchResult(payload);
      //setsearchContextResult(res);
      // eslint-disable-next-line no-console
      console.log("data", res);
      if (
        res.GetCustomers !== undefined &&
        res.GetCustomers.customers !== undefined &&
        res.GetCustomers.customers.length !== undefined &&
        res.GetCustomers.customers.length > 0
      ) {
        setblurLoaderActive(false);
        const getCustomerList: any = [];
        res.GetCustomers.customers.forEach((customer: any) => {
          const agreementCount =
            customer.agreementCounts !== undefined
              ? activeAgreementCount(customer.agreementCounts)
              : 0;
          customer.AgreementCount = agreementCount;
          getCustomerList.push(customer);
        });
        // getCustomerList = getCustomerList.sort((a: any, b: any) =>
        //   a['firstName'] < b['firstName'] ? -1 : 1
        // );
        // let getCustomerOrginStore = getCustomerList.filter(
        //   (value: any) => value.originStore == loginStore
        // );
        // getCustomerOrginStore = getCustomerOrginStore.sort((a: any, b: any) =>
        //   a['AgreementCount'] < b['AgreementCount'] ? -1 : 1
        // );
        // const getCustomerNonOrginStore = getCustomerList.filter(
        //   (value: any) => value.originStore != loginStore
        // );
        // getCustomerList = [];
        // getCustomerOrginStore.forEach((element: any) => {
        //   getCustomerList.push(element);
        // });
        // getCustomerNonOrginStore.forEach((element: any) => {
        //   getCustomerList.push(element);
        // });
        setsearchResults(getCustomerList);
        setEnbleLoaderButton(false);
        setinitialNoRecPopUp(false);
      } else if (res.GetCustomers.customers.length == 0) {
        setblurLoaderActive(false);
        setsearchResults([]);
        setEnbleLoaderButton(false);
        setinitialNoRecPopUp(false);
      }
    } catch (err: any) {
      setblurLoaderActive(false);
      setisUnableTofetchData(true);
      setEnbleLoaderButton(false);
      setinitialNoRecPopUp(false);
      console.log("err", err);
    }
  };
  const formatTextChange = (value: any) => {
    return value !== undefined && value !== null && value.trim().length == 0
      ? value.trim()
      : value;
  };

  const HandleChange = (e: any, name?: any) => {
    if (name === "DOB") {
      const InputTxt = e;
      const format = moment(InputTxt).format("MM/DD/YYYY");
      const temp = InputTxt.replace(format);
      handleChange("Dob", temp);
    } else {
      if (e.target.name === "FirstName") {
        if (e.target.value && e.target.value.length > 0 && e.target.value.charAt(e.target.value.length - 1) !== " ") {
          const char = e.target.value;
          handleChange("FirstName", formatTextChange(char.replace(/[^A-Z-'.,’ a-z]/gi, "")));
        } else {
          handleChange("FirstName", formatTextChange(e.target.value.replace(/[^A-Z-'.,’ a-z]/gi, "")));
        }
      } else if (e.target.name === "LastName") {
        if (e.target.value && e.target.value.length > 0 && e.target.value.charAt(e.target.value.length - 1) !== " ") {
          const char = e.target.value;
          handleChange("LastName", formatTextChange(char.replace(/[^A-Z-'.,’ a-z]/gi, "")));
        } else {
          handleChange("LastName", formatTextChange(e.target.value.replace(/[^A-Z-'.,’ a-z]/gi, "")));
        }
      } else if (e.target.name === "Ssn1") {
        handleChange("Ssn1", e.target.value.replace(/\D/g, ""));
        ssn1Handler(e);
      } else if (e.target.name === "Ssn2") {
        handleChange("Ssn2", e.target.value.replace(/\D/g, ""));
        ssn2Handler(e);
      } else if (e.target.name === "Ssn3") {
        handleChange("Ssn3", e.target.value.replace(/\D/g, ""));
        ssn3Handler(e);
      } else if (e.target.name === "phoneNumber") {
        handleChange("Phonenumber", e.target.value);
      } else if (e.target.name === "agreementNumber") {
        handleChange("agreementNumber", e.target.value);
      } else if (e.target.name === "zip") {
        handleChange("zip", e.target.value);
      } else if (e.target.name === "addressLine1") {
        handleChange("addressLine1", e.target.value);
      } else if (e.target.name === "addressLine2") {
        handleChange("addressLine2", e.target.value);
      } else if (e.target.name === "city") {
        handleChange("city", e.target.value);
      } else if (e.target.name === "state") {
        handleChange("state", e.target.value);
      }
    }
  };

  const enterKeyPressed = (event: any) => {
    // Number 13 is the "Enter" key on the keyboard
    console.log("key pressed");

    if (event.charCode === 13 && !disableSearchBtn) {
      // Cancel the default action, if needed
      event.preventDefault();
      console.log("enter pressed");

      // Trigger the button element with a click
      showSearchResults();
    }
  };
  const ssn2Handler = (e: any) => {
    if (e.target.value.length === 2) {
      (document.getElementById("a11y_ssnthree") as HTMLInputElement).focus();
    }
    if (e.target.value.length === 0 && searchData.Ssn1?.length > 0) {
      (document.getElementById("a11y_ssnone") as HTMLInputElement).focus();
    }
  };
  const ssn1Handler = (e: any) => {
    if (e.target.value.length === 3) {
      (document.getElementById("a11y_ssntwo") as HTMLInputElement).focus();
    }
  };
  const ssn3Handler = (e: any) => {
    if (e.target.value.length === 0 && searchData.Ssn2?.length > 0) {
      (document.getElementById("a11y_ssntwo") as HTMLInputElement).focus();
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const showAgreement = async (i: any, gcId: any) => {
    if (checkPermissionForCusRed()) {
      // eslint-disable-next-line no-debugger
      //debugger;
      console.log(gcId);
      //setcheckBoxActive(undefined);
      const res = await getcustomerbyid("C", gcId);
      console.log("response for customer id", res);
      if (res == undefined) {
        setcheckBoxActive(undefined);
        setshowCustomerAgrIndex(undefined);
      } else {
        setcheckBoxActive(i == showCustomerAgrIndex ? undefined : i);
        setshowCustomerAgrIndex(i == showCustomerAgrIndex ? undefined : i);
        if (i != showCustomerAgrIndex) {
          console.log("inside if condition", showCustomerAgrIndex);
          setshowCustomerAgrIndex(i == showCustomerAgrIndex ? undefined : i);
          const response = await getAgreement(res.customerId);
          setCustomerId(res.customerId);
          console.log("response for agreement", response);
          // if (response) {
          //   const res = await getcustomerbyid('C', gcId);
          //   // eslint-disable-next-line no-console
          //   console.log('response for customer id', res);
          //   if (res == undefined) {
          //     setcheckBoxActive(undefined);
          //     setshowCustomerAgrIndex(undefined);
          //   } else {
          //     setcheckBoxActive(i == showCustomerAgrIndex ? undefined : i);
          //     setshowCustomerAgrIndex(i == showCustomerAgrIndex ? undefined : i);
          //   }
          // }
        } else {
          console.log("inside else condition");
          setcheckBoxActive(i == showCustomerAgrIndex ? i : undefined);
          //setcheckBoxActive(undefined);
          setshowCustomerAgrIndex(undefined);
        }
      }
    }
  };
  const getAgreement = async (id: any) => {
    try {
      setagreements(undefined);
      const res = await GetAgreementById(id, "N");
      const agreement =
        res != undefined && res.agreement !== undefined ? res.agreement : [];
      if (
        res.clubInfoResponse !== undefined &&
        res.clubInfoResponse !== null &&
        res.clubInfoResponse.length > 0
      ) {
        res.clubInfoResponse.forEach((el: any) => {
          res.agreement.push(el);
        });
      }
      if (agreement == []) {
        //showCustomerAgrIndex(undefined);
        setagreements([]);
      } else {
        const agreementList = showRecordsByStoreNumber(res.agreement);
        setagreements(agreementList);
      }
      console.log("getAgreement", res);
      return true;
    } catch (err: any) {
      if (err) {
        setagreements(null);
        return false;
      }
    }
  };
  const formatNum = (val: any) => {
    const cleaned = ("" + val).replace(/\D/g, "");
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return cleaned.replace(PhoneRegex, "($1) $2-$3");
  };

  const activeAgreementCount = (data: any) => {
    let initialAgreement = 0;
    if (data !== undefined && data !== null && data.length > 0) {
      data.forEach((el: any) => {
        if (el.lob !== undefined && el.lob.toUpperCase() == "RTO") {
          initialAgreement += Number(el.active);
        }
      });
    }
    return initialAgreement;
  };
  const paymentRedirect = () => {
    if (custID.customerId !== undefined) {
      history.push(`/payment1/paymentinformation/${custID.customerId}/0`);
    }
  };
  const ReversePaymentRedirect = () => {
    if (custID.customerId !== undefined) {
      history.push(`/payment1/reversepayment/${custID.customerId}`);
    }
  };
  const agreementRedirect = () => {
    if (custID.customerId !== undefined) {
      history.push(`/agreement/rental/itemsearch/${custID.customerId}`);
    }
  };
  const checkBoxActiveOnclick = async (index: number, gcd: any) => {
    // if(checkPermissionForCusRed()){
    const agr: any = searchResults.filter((el: any) => {
      if (gcd == el.globalCustomerId) {
        return el;
      }
    });
    setagrDetails(agr);
    setcheckBoxActive(undefined);
    setshowCustomerAgrIndex(undefined);
    const currentCUstomerChecked: number | undefined =
      checkBoxActive == index ? undefined : index;
    if (currentCUstomerChecked !== undefined) {
      // setsomethingone('modal fade show expandPopup');
      // setsomethingtwo('modal fade');
      setblurLoaderActive(true);
      const res = await getcustomerbyid("C", gcd);
      console.log("custID.customerId", res);
      if (res == undefined) {
        setcheckBoxActive(undefined);
      } else {
        setcheckBoxActive(currentCUstomerChecked);
      }
    } else {
      setcheckBoxActive(currentCUstomerChecked);
    }
    // }
  };
  const getcustomerbyid = async (type: any, id: any) => {
    try {
      if (checkPermissionForCusRed()) {
        const agr: any = searchResults.filter((el: any) => {
          if (id == el.globalCustomerId) {
            return el;
          }
        });
        setagrDetails(agr);
        // eslint-disable-next-line no-debugger
        debugger;
        setblurLoaderActive(true);
        const res: any = await GetCustomerBasicInfo(id);
        if (res && res.customerId) {
          setblurLoaderActive(false);
          console.log(res);
          if (type == "C") {
            setcustID(res);
            return res;
          } else if (type == "R" && checkPermissionForPaymentRed()) {
            // checkPermissionForCusRed(res.customerId)
            history.push(`/payment1/paymentinformation/${res.customerId}/0`);
          }
        } else {
          setblurLoaderActive(false);
          setisclsAlertMsg(true);
        }
      }
    } catch (err: any) {
      if (err) {
        setblurLoaderActive(false);
        setisclsAlertMsg(true);
        console.log("error while fetching customerid", err, isclsAlertMsg);
      }
    }
  };

  const agreementTable = (i: any) => {
    return showCustomerAgrIndex == i ? (
      <RACTableRow
        className={`{${classes.customerAccordianopen} ${classes.customerTablecellbgcolor}`}
      >
        <td
          colSpan={15}
          className={`${classes.customerHiddenrow} ${classes.spacerP0}`}
        >
          <div id="row-1" className={`${classes.agreementGridInnerRow}`}>
            {agreements === undefined ? (
              <div className={classes.customerNorecordsAgr}>
                <CircularProgress />
              </div>
            ) : agreements === null ? (
              <div className={classes.customerTextcenter}>
                <div
                  className={`${classes.customerRow} ${classes.customerJustifycontentcenter}`}
                >
                  <div className={classes.customerColmd}>
                    <Alerticon></Alerticon>
                    <p className={classes.spacerMT3}>
                      No rental agreement found
                    </p>
                  </div>
                </div>
              </div>
            ) : agreements !== undefined &&
              agreements !== null &&
              agreements.length > 0 ? (
              <RACTable
                renderTableHead={renderAgreementTableHead}
                renderTableContent={renderAgreementTableContent}
                className={`${classes.agreementGrid}`}
              />
            ) : (
              <div className={classes.customerTextcenter}>
                <div
                  className={
                    (classes.customerRow, classes.customerJustifycontentcenter)
                  }
                >
                  <div className={classes.customerColmd}>
                    <Alerticon></Alerticon>
                    <p className={classes.spacerMT3}>
                      No rental agreements found
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </td>
      </RACTableRow>
    ) : null;
  };
  const category = (el: any) => {
    const skip = el.skip !== undefined ? el.skip : "N";
    const stolen = el.stolen !== undefined ? el.stolen : "N";
    const hard = el.hard !== undefined ? el.hard : "N";
    if (skip == "N" && stolen == "N" && hard == "N") {
      return "-";
    } else if (skip !== "N" && hard !== "N") {
      return "Skip/Hard";
    } else if (skip !== "N") {
      return "Skip";
    } else if (stolen !== "N") {
      return "Stolen";
    } else if (hard !== "N") {
      return "Hard";
    }
  };
  const showRecordsByStoreNumber = (agreement: []) => {
    if (agreement.length > 0) {
      const GetStoreByActiveOrPending = agreement.filter((element: any) => {
        if (
          element.agreementStatus !== undefined &&
          (element.agreementStatus.toLowerCase() == "active" ||
            element.agreementStatus.toLowerCase() == "pending")
        ) {
          return element;
        }
      });
      console.log("311", GetStoreByActiveOrPending);
      const filterStoreNum = GetStoreByActiveOrPending.map(
        (el: any) => el.storeNumber
      );
      console.log("317", filterStoreNum);

      const uniqueSN = Array.from(new Set(filterStoreNum));
      console.log("322", uniqueSN);
      let data: { storeNumber: string; agreements: [] }[] = [];
      if (
        GetStoreByActiveOrPending !== undefined &&
        GetStoreByActiveOrPending.length > 0 &&
        uniqueSN.length > 0
      ) {
        uniqueSN.forEach((val) => {
          const agreementValue = GetStoreByActiveOrPending.filter(
            (value: any) => val == value.storeNumber
          );
          const storeNumberWithAgreement: any = {
            storeNumber: val,
            agreements: agreementValue,
          };
          data.push(storeNumberWithAgreement);
        });
      }
      if (loginStore !== undefined && loginStore !== null) {
        const getCurrentStoreAgreements = data.filter(
          (val: any) => val.storeNumber == loginStore
        );
        const getNotCurrentStoreAgreements = data.filter(
          (val: any) => val.storeNumber != loginStore
        );
        data = [];
        getCurrentStoreAgreements.forEach((agreemts) => {
          data.push(agreemts);
        });
        getNotCurrentStoreAgreements.forEach((agreemts) => {
          data.push(agreemts);
        });
      }

      console.log("345", data);
      return data;
    }
  };
  const truncString = (str: any, max: number, add: any) => {
    add = add || "...";
    return str !== undefined &&
      str !== null &&
      typeof str === "string" &&
      str.length > max
      ? str.substring(0, max) + add
      : str;
  };
  const HandleDecimal = (value: any) => {
    const formatedValue: any = value.replace(",", "");
    console.log("formatedValue", formatedValue);
    const nfObject = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    });
    return nfObject.format(formatedValue);
  };
  const nextDueDayWithDate = (date: any) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date(date);
    const index = today.getDay();
    return days[index] !== undefined && days[index] !== null
      ? days[index] + ", " + date
      : date;
  };
  const isDueDatePassed = (date: any) => {
    console.log(date);
    const date1: any = new Date(date);
    const cMonth = dt.getMonth() + 1;
    const cDate = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
    const cYear = dt.getFullYear();
    const currentDate: any = new Date(`${cMonth}/${cDate}/${cYear}`);
    const diffTime = Math.abs(currentDate - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 ? false : true;
  };

  const showSearchResults = async () => {
    setSortOrder({ fN: "asc", lN: "asc" });
    setsearchResults([]);
    const request: any = {};

    const phones = [{ phoneNumber: "" }];
    const addresses = [{ addressLine1: "", addressLine2: "", city: "", state: "", postalCode: "" }];

    const { FirstName, LastName, Dob, Ssn1, Ssn2, Ssn3, Phonenumber, agreementNumber,
      state, addressLine1, addressLine2, zip, city } = searchData;

    const updatePayload = (key: string, value: any) => {
      if (value) {
        payloadReq[key] = value.trim ? value.trim() : value;
      }
    };

    const payloadReq: any = {};

    if (FirstName != "" && FirstName !== null && FirstName !== undefined) {
      updatePayload("firstName", FirstName);
      request.firstName = FirstName;
    }

    if (LastName != "" && LastName !== null && LastName !== undefined) {
      updatePayload("lastName", LastName);
      request.lastName = LastName;
    }

    if (Dob != "") {
      updatePayload("dateOfBirth", Dob);
      request.dob = Dob;
    }

    if (Ssn1 && Ssn2 && Ssn3) {
      payloadReq.taxId = Ssn1 + Ssn2 + Ssn3;
      request.taxId = Ssn1 + Ssn2 + Ssn3;
    }

    if (Phonenumber && Phonenumber.length >= 14) {
      phones[0].phoneNumber = Phonenumber.replace(/\D/g, "");
      payloadReq.phones = phones;
      request.phones = phones;
    }

    if (agreementNumber) {
      updatePayload("agreementNumber", agreementNumber);
      request.agreementNumber = agreementNumber;
    }

    if (addressLine1) {
      addresses[0].addressLine1 = addressLine1;
    }

    if (addressLine2) {
      addresses[0].addressLine2 = addressLine2;
    }

    if (zip) {
      addresses[0].postalCode = zip;
    }

    if (city) {
      addresses[0].city = city;
    }

    if (state && state !== "0") {
      addresses[0].state = state;
    }

    if (addressLine1.length || addressLine2.length || zip.length || city.length || (state.length && state != '0')) {
      payloadReq.addresses = addresses;
      request.addresses = addresses;
    }

    request.cancelClick = true;
    setsearchVal(request);

    if (Object.keys(payloadReq).length > 0) {
      await getSearchValues(payloadReq);
    } else {
      setinitialNoRecPopUp(true);
    }
  };
  // const CreateCustomer = () => {
  //   const searchVal = {
  //     firstName: FirstName,
  //     lastName: LastName,
  //     dob: Dob,
  //     ssn1: Ssn1,
  //     ssn2: Ssn2,
  //     ssn3: Ssn3,
  //     phoneNumber: Phonenumber,
  //   };
  //   setsearchVal(searchVal);
  //   props.history.push('/customer/createcustomer');
  // };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const sortGrid = (columnToSort: any, col: any) => {
    const searchResultslocal = searchResults;
    const sortedData =
      col == "FN"
        ? searchResultslocal.sort(function (a: any, b: any) {
            const x: any =
                a["firstName"] !== undefined && a["firstName"] !== null
                  ? a["firstName"].toUpperCase()
                  : "",
              y =
                b["lastName"] !== undefined && b["lastName"] !== null
                  ? b["lastName"].toUpperCase()
                  : "";
            // if (x == y) {
            //   return 0;
            // } else if (x > y && columnToSort.fN != 'asc') {
            //   return 1;
            // } else if (x > y && columnToSort.fN == 'asc') {
            //   return -1;
            // }
            return x == y ? 0 : x > y && columnToSort.fN != "asc" ? 1 : -1;
          })
        : searchResultslocal.sort(function (a: any, b: any) {
            const x =
                a["firstName"] !== undefined && a["firstName"] !== null
                  ? a["firstName"].toUpperCase()
                  : "",
              y =
                b["lastName"] !== undefined && b["lastName"] !== null
                  ? b["lastName"].toUpperCase()
                  : "";
            // if (x == y) {
            //   return 0;
            // } else if (x > y && columnToSort.fN != 'asc') {
            //   return 1;
            // } else if (x > y && columnToSort.fN == 'asc') {
            //   return -1;
            // }
            return x == y ? 0 : x > y && columnToSort.lN != "asc" ? 1 : -1;
          });
    setsearchResults([...sortedData]);
    //setSortOrder({ fN: 'asc', lN: 'asc' });
    console.log("columnToSort", columnToSort);
    let data: any;
    if (col == "FN") {
      data = { fN: columnToSort, lN: sortOrder.lN };
    } else {
      data = { fN: sortOrder.fN, lN: columnToSort };
    }
    // })
    setSortOrder(data);
    setcheckBoxActive(undefined);
    setshowCustomerAgrIndex(undefined);
  };
  const CustNotFoundPopUp = () => {
    return (
      <Grid item md={12} className={classes.px2}>
        <Grid item md={12} className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.RACPOPMsg}>
            Customer record is incomplete, please create new customer.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.spacerMT4} ${classes.justifyContentCenter}`}
        >
          <RACButton
            color="primary"
            variant="contained"
            onClick={() => {
              setcreatecustomerPopup(true);
              setisclsAlertMsg(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );

    // Reason popup for Not running DE PC-CC-29
  }
  const GetCustIDAlert = () => {
    return (
      <RACModalCard
        isOpen={isclsAlertMsg}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setisclsAlertMsg(false)}
        // eslint-disable-next-line react/no-children-prop
        children={CustNotFoundPopUp()}
        title=""
      />
    );
  }

  const createcustomermodalPopup = () => {
    return (
      <CreateCustomerComponent
        basicInfo={agrDetails}
        handleClosePopUp={(action: boolean) => {
          setcreatecustomerPopup(action);
        }}
        useGoogleAddressValidation={canEnableGoogleValdation}
      />
    );
  };
  const CreateCustomerModal = () => {
    return (
      <RACModalCard
        isOpen={createcustomerPopup}
        borderRadius="20px"
        maxWidth="lg"
        closeIcon={true}
        onClose={() => setcreatecustomerPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={createcustomermodalPopup()}
        title=""
      />
    );
  }

  const EnterSSNPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography variant={"body2"} className={classes.RACPOPMsg}>
            Enter the SSN# to run the decision engine.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            onClick={() =>
              custID !== undefined &&
              custID.customerId !== undefined &&
              checkPermissionForCusRed()
                ? history.push(`/customer/update/${custID.customerId}/customer`)
                : null
            }
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const EnterSSNfn = () => {
    return (
      <RACModalCard
        isOpen={enterssnisopen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setenterssnisopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={EnterSSNPopup()}
        title=""
      />
    );
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const runDecisionEngine = async () => {
    console.log("DecisionEngine customer id", custID);
    console.log("searchResults", searchResults);

    if (custID !== undefined && custID.globalCustomerId !== undefined) {
      const gcd = custID.globalCustomerId;
      const filterData = searchResults.filter((el: any) => {
        return el.globalCustomerId == gcd;
      });
      console.log("filterData", filterData);
      if (
        filterData == undefined ||
        filterData[0].taxId == null ||
        filterData[0].taxId == ""
      ) {
        setenterssnisopen(true);
      } else {
        const address: any =
          filterData !== undefined &&
          filterData !== null &&
          filterData.length > 0 &&
          filterData[0].addresses !== undefined &&
          filterData[0].addresses !== null &&
          filterData[0].addresses.length > 0
            ? filterData[0].addresses.filter(
                (e: any) => e.addressType == "PRIM" || e.addressType == "P"
              )
            : [];
        const phones: any =
          filterData !== undefined &&
          filterData !== null &&
          filterData.length > 0 &&
          filterData[0].phones !== undefined &&
          filterData[0].phones !== null &&
          filterData[0].phones.length > 0
            ? filterData[0].phones.filter((e: any) => e.isPrimary == "Y")
            : [];
        const payload: any = {
          firstName:
            filterData !== undefined && filterData[0].firstName
              ? filterData[0].firstName
              : "",
          lastName:
            filterData !== undefined && filterData[0].lastName
              ? filterData[0].lastName
              : "",
          dateOfBirth:
            filterData !== undefined && filterData[0].dateOfBirthEnc
              ? filterData[0].dateOfBirthEnc
              : "",
          storeNumber: loginStore,
          taxId:
            filterData !== undefined && filterData[0].taxIdEnc
              ? filterData[0].taxIdEnc
              : "",
          customerId: custID.customerId,
          address: {
            addressType:
              filterData !== undefined && address !== undefined
                ? address[0].addressType
                : "",
            addressLine1:
              filterData !== undefined && address !== undefined
                ? address[0].addressLine1
                : "",
            addressLine2:
              filterData !== undefined && address !== undefined
                ? address[0].addressLine2
                : "",
            city:
              filterData !== undefined && address !== undefined
                ? address[0].city
                : "",
            state:
              filterData !== undefined && address !== undefined
                ? address[0].stateCode
                : "",
            postalCode:
              filterData !== undefined && address !== undefined
                ? address[0].postalCode
                : "",
          },
          phone: {
            phoneNumber:
              filterData !== undefined && phones !== undefined
                ? phones[0].phoneNumber
                : "",
            phoneType:
              filterData !== undefined && phones !== undefined
                ? phones[0].phoneType
                : "",
            primary:
              filterData !== undefined && phones !== undefined
                ? phones[0].isPrimary
                : "",
            callTimeType: "MORN",
          },
          globalCustomerId:
            filterData?.length > 0 && filterData[0]?.globalCustomerId
              ? filterData[0]?.globalCustomerId
              : "",
        };
        console.log("payloaad", payload);
        setblurLoaderActive(true);
        await runDEaxiosCall(payload);
      }
    }
  };
  const runDEaxiosCall = async (payload: any) => {
    try {
      const response = await RunDE(payload);
      if (
        response !== undefined &&
        response.approvalResponse !== undefined &&
        (response.approvalResponse == "1" ||
          response.approvalResponse == "2" ||
          response.approvalResponse == "4")
      ) {
        setblurLoaderActive(false);
        setDEStatus(response.approvalResponse);
        setdedeclineisopen(true);
        console.log("Run DE REsp", response);
      }
      console.log("Run DE REsp1", response);
    } catch (e) {
      setblurLoaderActive(false);
      setDEStatus("");
      setdedeclineisopen(true);
      // console.log('payloaad', payload);
      // const runderesponse = await RunDE(payload);

      // console.log('runderesponse', runderesponse);
    }
  };

  const SearchGridHeader = [
    "",
    "",
    "Last Name",
    "First Name",
    "MI",
    "Suffix",
    "Date of Birth",
    "Address",
    "City",
    "State",
    "Zip Code",
    "Phone#",
    "DE",
    "Category",
    "Pending/Active Agr #",
  ];
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderSearchTableHead = () => (
    <>
      {SearchGridHeader.map((col: string, index: number) => {
        if (col == "Last Name") {
          return (
            <RACTableCell
              className={`${classes.customerTablecellbgcolor} ${classes.gridBorderBottom}`}
              key={index}
            >
              {col}
              <span>
                <a
                  onClick={() =>
                    sortGrid(sortOrder.lN == "asc" ? "desc" : "asc", "LN")
                  }
                >
                  {sortOrder.lN == "asc" ? (
                    <SortDownIcon className={classes.spacerMS1}></SortDownIcon>
                  ) : (
                    <SortUpIcon className={classes.spacerMS1}></SortUpIcon>
                  )}
                </a>
              </span>
            </RACTableCell>
          );
        } else if (col == "First Name") {
          return (
            <RACTableCell
              className={`${classes.customerTablecellbgcolor} ${classes.gridBorderBottom}`}
              key={index}
            >
              {col}
              <span>
                <a
                  onClick={() =>
                    sortGrid(sortOrder.fN == "asc" ? "desc" : "asc", "FN")
                  }
                >
                  {sortOrder.fN == "asc" ? (
                    <SortDownIcon className={classes.spacerMS1}></SortDownIcon>
                  ) : (
                    <SortUpIcon className={classes.spacerMS1}></SortUpIcon>
                  )}
                </a>
              </span>
            </RACTableCell>
          );
        } else {
          return (
            <RACTableCell
              className={`${classes.customerLineBreak} ${classes.customerTablecellbgcolor} ${classes.gridBorderBottom}`}
              key={index}
            >
              {col}
            </RACTableCell>
          );
        }
      })}
    </>
  );

  const renderSearchTableContent = () => {
    //if (loading) return <></>;

    return searchResults !== undefined &&
      searchResults !== null &&
      searchResults.length > 0
      ? renderSearchTableContentFilled()
      : renderContentNoRecordFound();
  };
  /* eslint-disable sonarjs/cognitive-complexity */
  const renderSearchTableContentFilled = () => {
    return searchResults.map((el: any, i: any) => {
      const address: any =
        el.addresses !== undefined &&
        el.addresses !== null &&
        el.addresses.length > 0
          ? el.addresses.filter(
              (e: any) => e.addressType == "PRIM" || e.addressType == "P"
            )
          : [];
      const phones: any =
        el.phones !== undefined && el.phones !== null && el.phones.length > 0
          ? el.phones.filter((e: any) => e.isPrimary == "Y")
          : [];
      const currentDate = moment();
      const targetDate = moment(el.approvalEndDate);
      const differenceInDays = targetDate.diff(currentDate, 'days');
      return (
        <React.Fragment key={i}>
          <RACTableRow
            key={i}
            className={
              showCustomerAgrIndex !== i
                ? `${classes.customerTablecellbgcolor}`
                : `${classes.customerTablecellbgcolor} ${classes.cusomerInfo}`
            }
          >
            {el.AgreementCount != 0 ? (
              <RACTableCell
                data-bs-toggle="collapse"
                onClick={() => showAgreement(i, el.globalCustomerId)}
                data-bs-target="#row-1"
                aria-expanded={showCustomerAgrIndex == i ? true : false}
                className={`${classes.accordianWidth}`}
              >
                {showCustomerAgrIndex !== i ? (
                  <img
                    className={`${classes.accordionIconGrid} ${classes.me2}`}
                    src={AccordianCloseIcon}
                  />
                ) : (
                  <img
                    className={`${classes.accordionIconGrid} ${classes.me2}`}
                    src={AccordianOpenIcon}
                  />
                )}
                {/* <AccordianCloseIcon className={classes.me2} /> */}
                {/* <span
                  className={
                    showCustomerAgrIndex !== i
                      ? `${classes.accordionIconGrid} ${classes.me2} ${classes.accordionGridClose}`
                      : `${classes.accordionIconGrid} ${classes.me2}`
                  }
                ></span> */}
              </RACTableCell>
            ) : (
              <RACTableCell className={classes.customerTablecellbgcolor}>
                <span>{""}</span>
              </RACTableCell>
            )}
            <RACTableCell
              classes={{
                root: clsx(
                  classes.checkBoxwidth,
                  classes.customerTablecellbgcolor
                ),
              }}
            >
              <RACCheckBox
                label=""
                size="small"
                className={classes.customerCheckbox}
                name={`checkbox_${i}`}
                onClick={() => checkBoxActiveOnclick(i, el.globalCustomerId)}
                checked={
                  checkBoxActive !== undefined && checkBoxActive == i
                    ? true
                    : false
                }
              />
            </RACTableCell>
            <RACTableCell className={classes.customerTablecellbgcolor}>
              <a
                onClick={() => getcustomerbyid("R", el.globalCustomerId)}
                className={classes.customerRaclink}
              >
                {el.lastName}
              </a>
            </RACTableCell>
            <RACTableCell className={classes.customerTablecellbgcolor}>
              <a
                onClick={() => getcustomerbyid("R", el.globalCustomerId)}
                className={classes.customerRaclink}
              >
                {el.firstName}
              </a>
            </RACTableCell>
            <RACTableCell
              className={
                el.middleName !== undefined &&
                el.middleName !== null &&
                el.middleName !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {el.middleName !== undefined &&
              el.middleName !== null &&
              el.middleName !== ""
                ? el.middleName
                : "-"}
            </RACTableCell>
            <RACTableCell
              className={
                el.suffix !== undefined &&
                el.suffix !== null &&
                el.suffix !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {el.suffix !== undefined && el.suffix !== null && el.suffix !== ""
                ? el.suffix
                : "-"}
            </RACTableCell>
            <RACTableCell
              className={
                el.dateOfBirth !== undefined &&
                el.dateOfBirth !== null &&
                el.dateOfBirth !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {el.dateOfBirth !== undefined && el.dateOfBirth != null
                ? el.dateOfBirth.split("-")[1] +
                  "/" +
                  el.dateOfBirth.split("-")[2] +
                  "/" +
                  "****"
                : ""}
            </RACTableCell>
            <RACTableCell
              className={
                address !== undefined &&
                address !== null &&
                address.length > 0 &&
                address[0].addressLine1 !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].addressLine1
                : ""}
            </RACTableCell>
            <RACTableCell
              className={
                address !== undefined &&
                address !== null &&
                address.length > 0 &&
                address[0].city !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].city
                : ""}
            </RACTableCell>
            <RACTableCell
              className={
                address !== undefined &&
                address !== null &&
                address.length > 0 &&
                address[0].stateCode !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].stateCode
                : ""}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerLinebreak,
                  classes.customerTablecellbgcolor
                ),
              }}
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].postalCode
                : ""}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerLinebreak,
                  classes.customerTablecellbgcolor
                ),
              }}
            >
              {phones !== undefined && phones !== null && phones.length > 0
                ? formatNum(phones[0].phoneNumber)
                : ""}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerPointercursor,
                  classes.customerTablecellbgcolor
                ),
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title={
                el.approvalStatus !== undefined && el.approvalStatus !== null
                  ? el.approvalStatus.toUpperCase() == "APPROVE" &&
                    differenceInDays < 0
                    ? "DE : Expired"
                    : el.approvalStatus.toUpperCase() == "APPROVE" &&
                      differenceInDays >= 0
                    ? "DE : Approved"
                    : el.approvalStatus.toUpperCase() == "DECLINE"
                    ? "DE : Declined"
                    : (el.approvalStatus.toUpperCase() ==
                        "MANUAL_INTERVENTION" ||
                        el.approvalStatus.toUpperCase() ==
                          "CONDITIONAL APPROVAL") &&
                      DE_APPROVAL_FLOW
                    ? "DE : Approved"
                    : el.approvalStatus.toUpperCase() ==
                        "MANUAL_INTERVENTION" ||
                      el.approvalStatus.toUpperCase() == "CONDITIONAL APPROVAL"
                    ? "DE : Conditionally Approved"
                    : "No DE"
                  : "No DE"
              }
            >
              {el.approvalStatus !== undefined && el.approvalStatus !== null ? (
                el.approvalStatus.toUpperCase() == "APPROVE" &&
                differenceInDays < 0 ? (
                  <DEDeclined
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEDeclined>
                ) : el.approvalStatus.toUpperCase() == "APPROVE" &&
                  differenceInDays >= 0 ? (
                  <DEApproved
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEApproved>
                ) : el.approvalStatus.toUpperCase() == "DECLINE" ? (
                  <DEDeclined
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEDeclined>
                ) : (el.approvalStatus.toUpperCase() == "MANUAL_INTERVENTION" ||
                    el.approvalStatus.toUpperCase() ==
                      "CONDITIONAL APPROVAL") &&
                  DE_APPROVAL_FLOW ? (
                  <DEApproved
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEApproved>
                ) : el.approvalStatus.toUpperCase() == "MANUAL_INTERVENTION" ||
                  el.approvalStatus.toUpperCase() == "CONDITIONAL APPROVAL" ? (
                  <DEConditionallyApproved
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEConditionallyApproved>
                ) : (
                  <DEDeclined
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEDeclined>
                )
              ) : (
                <DEDeclined
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                ></DEDeclined>
              )}
            </RACTableCell>
            <RACTableCell
              className={
                category(el) !== "-"
                  ? `${classes.customerPointercursor} ${classes.customerTablecellbgcolor} ${classes.textCenter}`
                  : classes.textCenter
              }
            >
              {category(el)}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerTextend,
                  classes.customerTablecellbgcolor,
                  classes.textCenter
                ),
              }}
              className={
                el.AgreementCount !== undefined &&
                el.AgreementCount !== null &&
                el.AgreementCount !== "" &&
                el.AgreementCount !== NaN
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {isNaN(el.AgreementCount) ? "-" : el.AgreementCount}
            </RACTableCell>
          </RACTableRow>
          {agreementTable(i)}
        </React.Fragment>
      );
    });
  };
  const renderAgreementTableContentFilled = () => {
    console.log(agreements, 'agreements');
    const exchangeAgreementDeductions: any = agreements[0]?.agreements;
    const exchangeAgreementsList: any = {};
    const agreementStatus : any = {};
    exchangeAgreementDeductions.forEach((element: any) => {
      exchangeAgreementsList[element?.agreementId] = element?.parentAgreementId;
      agreementStatus[element?.agreementId] = element?.isExchangeAgreement
    });
    console.log(exchangeAgreementsList, 'List of the Parent Agreements');
    return agreements.map((el: any, i: any) => {
      return (
        <React.Fragment key={i}>
          <RACTableRow key={i}>
            <RACTableCell
              className={`${classes.customerTextsubtilegrid} ${classes.customerTablecellbgcolor}`}
              colSpan={15}
            >
              {el.storeNumber +
                " -  " +
                el.agreements[0].storeCity +
                ", " +
                el.agreements[0].stateProvinceAbbreviation}
            </RACTableCell>
          </RACTableRow>
          {el.agreements.map((el1: any, index: any) => {
            const passDue =
              el1.currentDayslate !== undefined &&
              el1.currentDayslate !== null &&
              el1.currentDayslate !== "" &&
              el1.currentDayslate !== "0" &&
              el1.currentDayslate !== 0
                ? false
                : true;
            return (
              <RACTableRow
                key={index}
                className={
                  passDue === false
                    ? `${classes.paymentIssue} ${classes.paymentIssue} ${classes.customerTablecellbgcolor}`
                    : `${classes.agreementRow} ${classes.customerTablecellbgcolor}`
                }
              >
                <RACTableCell>
                  {(el1.agreementStatus == "Pending" ||
                    el1.agreementStatus == "Active") &&
                  el1.agreementType !== "club" ? (
                    <a
                      className={`${classes.racpadAgrLink} ${
                        classes.focforLinkPaymentIssue
                      } ${
                        passDue === false
                          ? classes.racpadPaymentFails
                          : classes.racpadPaymentSuccess
                      } ${classes.racpadLink}`}
                      style={{ padding: "3px !important" }}
                        href={
                          checkPermissionForAgrRed()
                            ? exchangeAgreementsList[el1.agreementId] && agreementStatus[el1.parentAgreementId] && isExchangeFuncEnabled ?
                              `/agreement/info/details/${custID?.customerId}/${el1.agreementId}?isExchange=1&exchangeagreementid=${exchangeAgreementsList[el1.agreementId]}`
                              : `/agreement/info/details/${custID?.customerId}/${el1.agreementId}`
                            : "#"
                        }
                    >
                      {el1.agreementNumber}
                    </a>
                  ) : (
                    <a
                      className={`${classes.racpadAgrLink} ${
                        passDue === false
                          ? classes.racpadPaymentFails
                          : classes.racpadPaymentSuccess
                      } ${classes.racpadLink}`}
                      href={
                        checkPermissionForAgrRed()
                          ? `/agreement/info/details/${custID?.customerId}/${el1.agreementId}`
                          : "#"
                      }
                      style={{ padding: "3px !important" }}
                    >
                      {el1.agreementNumber}
                    </a>
                  )}
                  {el1.autoPay !== null &&
                  el1.autoPay !== undefined &&
                  el1.autoPay ? (
                    <span className={classes.badgeContainer}>AP</span>
                  ) : null}
                </RACTableCell>
                <RACTableCell>
                  {el1.agreementType !== undefined &&
                  el1.agreementType !== null &&
                  el1.agreementType !== "" &&
                  el1.agreementType === "Rental Agreement (RTO)"
                    ? "Rent"
                    : el1.agreementType !== undefined &&
                      el1.agreementType !== null &&
                      el1.agreementType !== "" &&
                      el1.agreementType === "club"
                    ? "Club"
                    : el1.agreementType !== undefined &&
                      el1.agreementType !== null &&
                      el1.agreementType !== ""
                    ? el1.agreementType
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  title={
                    el1.agreementDescription.length > 20
                      ? el1.agreementDescription
                      : ""
                  }
                  className={
                    el1.agreementDescription !== undefined &&
                    el1.agreementDescription !== null &&
                    el1.agreementDescription !== ""
                      ? ""
                      : `${classes.textCenter}`
                  }
                >
                  {el1.agreementDescription !== undefined &&
                  el1.agreementDescription !== null &&
                  el1.agreementDescription !== ""
                    ? truncString(el1.agreementDescription, 20, "...")
                    : "-"}
                </RACTableCell>
                <RACTableCell className={classes.lineBreak}>
                  {el1.paymentSchedule !== undefined &&
                  el1.paymentSchedule !== null &&
                  el1.paymentSchedule !== ""
                    ? el1.paymentSchedule
                    : "-"}
                </RACTableCell>
                <RACTableCell className={`${classes.lineBreak}`}>
                  {el1.currentDueDate !== undefined &&
                  el1.currentDueDate !== null &&
                  el1.currentDueDate !== ""
                    ? moment(nextDueDayWithDate(el1.currentDueDate)).format(
                        dateFormat
                      )
                    : "-"}
                </RACTableCell>
                <RACTableCell className={`${classes.lineBreak}`}>
                  {el1.isExchangeAgreement ? <span style={{color: '#fcd209', float: 'left'}}>Exchange In Progress</span>
                  :el1.nextDueDate !== undefined &&
                  el1.nextDueDate !== null &&
                  el1.nextDueDate !== ""
                    ? nextDueDayWithDate(el1.nextDueDate)
                    : "-"}
                </RACTableCell>
                <RACTableCell className={`${classes.customerTextend}`}>
                  {el1.lateLast10 !== undefined &&
                  el1.lateLast10 !== null &&
                  el1.lateLast10 !== ""
                    ? el1.lateLast10
                    : "0"}
                </RACTableCell>
                <RACTableCell className={`${classes.customerTextend}`}>
                  {el1.currentDayslate !== undefined &&
                  el1.currentDayslate !== null &&
                  el1.currentDayslate !== ""
                    ? el1.currentDayslate
                    : "0"}
                </RACTableCell>
                <RACTableCell className={`${classes.customerTextend}`}>
                  ${" "}
                  {el1.amountDue !== undefined &&
                  el1.amountDue !== null &&
                  el1.amountDue !== ""
                    ? el1.amountDue
                    : "0.00"}
                </RACTableCell>
                {isRetentionEnabled || isExchangeEnabled ? (
                  <RACTableCell className={(el1.isExchangeAgreement) && isExchangeFuncEnabled ? `${classes.textAlignCenter} ${classes.noWrap} ${classes.wrapContent} ${classes.positionRelative} ${classes.pointerEvents}` : `${classes.textAlignCenter} ${classes.noWrap} ${classes.wrapContent}`}>
                    {/* {
                    (el1.isExchangeAgreement) && isExchangeFuncEnabled ?
                      <Grid className={el1.isExchangeAgreement ? `${classes.overlay}` : ''}>

                      </Grid>
                      : null
                  } */}
                    {validateForExchange(isExchangeEnabled, el1?.agreementStatus, el1?.closeDate, el1?.currentDueDate, el1?.agreementOpenDate, el1?.isEppAgreement, el1.agreementType, exchangePeriod, returnPeriod) && el.storeNumber == String(window.sessionStorage.getItem("storeNumber")) ? (
                      <>
                        <RACTooltip placement={el1?.isDeliveryConfirmed == '0' ? 'top' : 'top-start'} title={<span className={`${classes.fontWidth}`}>{el1?.isDeliveryConfirmed == '0' ? 'Please confirm delivery to initiate an exchange' : 'Exchange'}</span>} classes={{ tooltip: classes.tooltipcss1, arrow: classes.tooltipStyling1 }}>
                          {/* <Grid className={el1?.isDeliveryConfirmed == '0' ? `${classes.positionRelative}` : ''}> */}
                          {/* {
                          el1?.isDeliveryConfirmed == '0' ? 
                            <Grid className={el1?.isDeliveryConfirmed == '0' ? `${classes.overlay2}` : ''}>
                            </Grid> : null
                        } */}
                          <ExchangeIcon className={isRetentionEnabled && el1.agreementType !== "club" && !el1?.isEppAgreement && el.storeNumber == String(window.sessionStorage.getItem("storeNumber")) ? (el1?.isDeliveryConfirmed == '0' || el1.isExchangeAgreement) ? `${classes.iconWidth1} ${classes.pointer} ${classes.MR1} ${classes.blur}` : `${classes.iconWidth1} ${classes.pointer} ${classes.MR1}` : (el1?.isDeliveryConfirmed == '0' || el1.isExchangeAgreement) ? `${classes.iconWidth1} ${classes.pointer} ${classes.blur}` : `${classes.iconWidth1} ${classes.pointer}`}
                            onClick={() => {
                              if (isExchangeFuncEnabled && el1?.isDeliveryConfirmed == '1') {
                                /* setting the value of the variable `openSLA` to `true`. */
                                setOpenSLA(true);
                                /* code is setting the `agreementId` property of the `el1` object as
                                the value for the `choosenAgreementID` variable. */
                                setChoosenAgreementID(el1?.agreementId);
                                setRentPaid(el1?.rentPaid);//RAC2
                                /* code is setting the variable `exchangeSelected` to `true` */
                                setExchangeSelected(true);
                                setExchangeAgreementNumber(el1?.agreementNumber)
                                setSuspenseAmount(el1?.suspenseAmount == '0.00' ? 0 : el1?.suspenseAmount)
                              }
                            }}
                          />
                          {/* </Grid> */}
                        </RACTooltip>
                      </>
                    ) : null}
                    {isRetentionEnabled && el1.agreementType !== "club" && !el1?.isEppAgreement &&
                      el.storeNumber ==
                      String(window.sessionStorage.getItem("storeNumber")) ? (
                      <RACTooltip placement={'top-start'} title={<span className={`${classes.fontWidth}`}>Retention</span>} classes={{ tooltip: classes.tooltipcss1, arrow: classes.tooltipStyling1 }}>
                        <RetentionIcon className={el1.isExchangeAgreement ? `${classes.iconWidth2} ${classes.pointer} ${classes.blur}` : `${classes.iconWidth2} ${classes.pointer}`} onClick={() => {
                          setOpenSLA(true);
                          setChoosenAgreementNumber(el1.agreementNumber);
                          setChoosenAgreementID(el1.agreementId);
                        }} />
                      </RACTooltip>
                    ) : null}
                    {
                      !(validateForExchange(isExchangeEnabled, el1?.agreementStatus, el1?.closeDate, el1?.currentDueDate, el1?.agreementOpenDate, el1?.isEppAgreement, el1.agreementType, exchangePeriod, returnPeriod) && el.storeNumber ==
                        String(window.sessionStorage.getItem("storeNumber"))) &&
                        !(isRetentionEnabled && el1.agreementType !== "club" && !el1?.isEppAgreement &&
                          el.storeNumber ==
                          String(window.sessionStorage.getItem("storeNumber"))) ? <span style={{ textAlign: 'center' }}>-</span>
                        : null
                    }
                  </RACTableCell>
                ) : null}
              </RACTableRow>
            );
          })}
        </React.Fragment>
      );
    });
  };
  /* eslint-disable sonarjs/cognitive-complexity */
  const renderContentNoRecordFound = () => {
    return (
      <RACTableRow hover backgroundColor="white">
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell>No Records Found</RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
      </RACTableRow>
    );
  };

  const acceptReinstate = async () => {
    setOpenActivityLoading(true);
    const today = new Date();
    const date = today.toISOString().split('T')[0];
    const agreementActivityPayloadObj = {
      agreementActivities: [
        {
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: 'No Longer Needs Selected',
          agreementActivityType: 'NLNS',
        },
      ],
    };
    try {
      await postAgreementActivity(agreementActivityPayloadObj);
    } catch {
      // Exception Handle
    }
    history.push({
      pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
      search: `?source=AMRETENTION&type=R`,
      state: {
        retentionData: offerResponse,
      },
    });
    setOpenActivityLoading(false);
  };
  const buildReinstateAlert = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <Typography className={`${classes.bold}`}>
            At any time you can contact your store to schedule a return of your
            product or return merchandise in person and pause your payments.
            When you are ready, you can come back, reinstate your agreement to
            get the same or comparable item, and pick up your payments right
            where you left off.
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenReinstateAlert(false);
              acceptReinstate();
            }}
          >
            Return
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenReinstateAlert(false);
              setOpenRetention(true);
            }}
          >
            Cancel
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const agreementGridHeader = [
    "Agreement #",
    "Type",
    "Description",
    "Schedule",
    "Current Due Date",
    "Next Due Date",
    "# Late Last 10",
    "Current Days Late",
    "Amount Due",
    "Action",
  ];
  // "Action",

  const renderAgreementTableHead = () => (
    <>
      {agreementGridHeader.map((col: string, index: number) => {
        const showAction = (isRetentionEnabled || isExchangeEnabled) && col == "Action" ? true : false;
        return showAction ? (
          <RACTableCell
            className={`${classes.lineBreak} ${classes.customerTablecellbgcolor} ${classes.Pl25}`}
            key={index}
          >
            {col}
          </RACTableCell>
        ) : col != "Action" ? (
          <RACTableCell
            className={
              col == "Total Cost" || col == "Amount Due"
                ? `${classes.colRight} ${classes.lineBreak} ${classes.customerTablecellbgcolor}`
                : col == "Next Due Date" || col == "# Payments"
                ? `${classes.textCenter} ${classes.lineBreak} ${classes.customerTablecellbgcolor}`
                : `${classes.lineBreak} ${classes.customerTablecellbgcolor}`
            }
            key={index}
          >
            {col}
          </RACTableCell>
        ) : null;
      })}
    </>
  );
  const renderAgreementTableContent = () => {
    //if (loading) return <></>;

    return agreements !== undefined &&
      agreements !== null &&
      agreements.length > 0
      ? renderAgreementTableContentFilled()
      : renderContentNoRecordFound();
  };
  // const createAgreementPopup = () => {
  //   return (
  //     <Grid item md={12}>
  //       <Grid item md={12} className={classes.textCenter}>
  //         <Typography className={classes.RACPOPMsg}>
  //           Would you like to add the Co-Customer for agreements?
  //         </Typography>
  //       </Grid>
  //       <Grid
  //         container
  //         className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
  //       >
  //         <RACButton
  //           className={classes.spacerMR2}
  //           size="small"
  //           variant="outlined"
  //           color="primary"
  //           onClick={() => agreementRedirect()}
  //         >
  //           No
  //         </RACButton>
  //         <RACButton
  //           size="small"
  //           variant="contained"
  //           color="primary"
  //           onClick={() => createAgreementYesHandleChange()}
  //         >
  //           Yes
  //         </RACButton>
  //       </Grid>
  //     </Grid>
  //   );
  // };
  // const addCoCustomerChildrenPopup = () => {
  //   return (
  //     <AddCoCustomer
  //       customerId={
  //         custID !== undefined && custID.customerId !== undefined
  //           ? custID.customerId
  //           : null
  //       }
  //       handleClosePopUp={(action: boolean) => {
  //         Setshowcocustomerpopup(action);
  //       }}
  //       showCustomerInfo={(customerId: string) => {
  //         props.history.push(`/customer/update/${customerId}/customer`);
  //       }}
  //       history={props.history}
  //       styles={customerStyles}
  //     ></AddCoCustomer>
  //   );
  // };
  // function AddCoCustomerPopup() {
  //   return (
  //     <RACModalCard
  //       isOpen={showcocustomerpopup}
  //       maxWidth="lg"
  //       closeIcon={true}
  //       onClose={() => Setshowcocustomerpopup(false)}
  //       // eslint-disable-next-line react/no-children-prop
  //       children={addCoCustomerChildrenPopup()}
  //       title="Co-Customer Information"
  //     />
  //   );
  // }
  // const createAgreementYesHandleChange = () => {
  //   setliketoCreateAgreement(false);
  //   Setshowcocustomerpopup(true);
  // };
  // function CreateAgreementrMsg() {
  //   return (
  //     <RACModalCard
  //       isOpen={liketoCreateAgreement}
  //       maxWidth="xs"
  //       closeIcon={true}
  //       onClose={() => setliketoCreateAgreement(false)}
  //       // eslint-disable-next-line react/no-children-prop
  //       children={createAgreementPopup()}
  //       title=""
  //     />
  //   );
  // }
  const postNoDESubmitClick = async () => {
    setreasonDEopen(false);
    if (
      custID !== undefined &&
      custID.globalCustomerId !== undefined &&
      custID.customerId !== undefined
    ) {
      const res: any = {
        globalCustomerId: custID.globalCustomerId,
        customerId: custID.customerId,
        deExceptionReason: postnodeReason,
      };
      console.log("res", res);
      const response = await UpdateNoDeReason(res);
      console.log(response);
      setdedeclineisopen(false);
    }
  };
  const DeOkclick = async () => {
    setdedeclineisopen(false);
    if (DEStatus == "") {
      setreasonDEopen(true);
    }
  };
  const dedeclineMsgPopup = () => {
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            {DEStatus == "" ? (
              <Typography variant={"body2"} className={classes.RACPOPMsg}>
                Error in approval process. Please proceed without approval
              </Typography>
            ) : (
              <Typography variant={"body2"}>
                {`Decision engine has ${
                  DEStatus == "1"
                    ? "approved"
                    : DEStatus == "2"
                    ? "declined"
                    : DEStatus == "4"
                    ? DE_APPROVAL_FLOW ? 'approved' : "conditionally approved"
                    : ""
                } the customer`}
              </Typography>
            )}
          </Grid>
          <Grid
            container
            className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
          >
            <RACButton
              color="primary"
              variant="contained"
              onClick={() => setdedeclineisopen(false)}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  // DE decline popup PC-CC-30
  const Dedecline = () => {
    return (
      <RACModalCard
        isOpen={dedeclineisopen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setdedeclineisopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={dedeclineMsgPopup()}
      />
    );
  }
  const noReasonDEPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={classes.RACPOPMsg}>
            Please specify the reason, why you are not running the decision
            engine?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACSelect
            inputLabel="Reason"
            options={noDEReasonDD}
            name="postNODEReason"
            defaultValue={
              postnodeReason !== undefined &&
              postnodeReason !== null &&
              postnodeReason !== ""
                ? postnodeReason
                : "0"
            }
            loading={noDEReasonDD.length == 0 && !dropDownLoaded ? true : false}
            onChange={(e: any) => {
              e.target.name = `postNODEReason`;
              setpostnodeReason(e.target.value);
            }}
            {...(noDEReasonDD.length == 0 &&
              dropDownLoaded && {
                errorMessage: API_ERROR_MESSAGE,
              })}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={classes.spacerMR2}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setreasonDEopen(false)}
          >
            Cancel
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => postNoDESubmitClick()}
            disabled={postnodeReason == "0" ? true : false}
          >
            Submit
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const NoDEReason = () => {
    return (
      <RACModalCard
        isOpen={reasonDEopen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setreasonDEopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={noReasonDEPopup()}
      />
    );
  }
  // const createAgreementHandler = () => {
  //   console.log('agreement handler', custID);
  //   if (
  //     custID.coworker !== undefined &&
  //     custID.coworker !== null &&
  //     custID.coworker == 'Y'
  //   ) {
  //     setcomingSoonPopup(true);
  //   } else {
  //     // if (
  //     //   custID !== undefined &&
  //     //   custID.governmentIdType !== undefined &&
  //     //   custID.governmentIdType !== null &&
  //     //   custID.governmentIdType !== '' &&
  //     //   custID.emailAddress !== undefined &&
  //     //   custID.emailAddress !== null &&
  //     //   custID.emailAddress !== ''
  //     // ) {
  //     //   setliketoCreateAgreement(true);
  //     // } else {
  //     //   setagreementMandatoryFieldpopup(true);
  //     // }
  //     agreementRedirect();
  //   }
  //   // agreementRedirect();
  // };
  const comingsoonpopUp = () => {
    return (
      <>
        <Grid className={`${classes.infoTextStyle}`}>
          <Typography>Coming Soon!</Typography>
        </Grid>
        <Grid
          container
          item
          xl={12}
          className={`${classes.px2} ${classes.pt2}`}
        >
          <CardContent className={`${classes.infoTextStyle} ${classes.mb5}`}>
            <Typography>
              {coWorkerRole == 1
                ? "Selected customer is a RAC employee. Only a DM (and above) can create an employee discounted Agreement"
                : coWorkerRole == 2
                ? "Selected customer is a RAC employee, please use SIMS for creating discounted agreement"
                : ""}
            </Typography>
          </CardContent>
        </Grid>
      </>
    );
  };

  const ComingSoonMsg = () => {
    return (
      <RACModalCard
        isOpen={comingSoonPopup}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setcomingSoonPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={comingsoonpopUp()}
        title=""
      />
    );
  }
  const AgreementMandotoryPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography variant={"body2"} className={classes.RACPOPMsg}>
            Enter the mandatory fields to create agreement
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            // eslint-disable-next-line sonarjs/no-identical-functions
            onClick={() =>
              custID !== undefined &&
              custID.customerId !== undefined &&
              checkPermissionForCusRed()
                ? history.push(`/customer/update/${custID.customerId}/customer`)
                : null
            }
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const AgreementMandatoryFieldpopup = () => {
    return (
      <RACModalCard
        isOpen={agreementMandatoryFieldpopup}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setagreementMandatoryFieldpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={AgreementMandotoryPopup()}
        title=""
      />
    );
  }

  //Retention Changes
  const SLACanceled = () => {
    setExchangeSelected(false);//RAC2
    setOpenSLA(false);
  };

  const clearbtnClick = () => {
    setSearchData(initialState);
  };

  const SLACompleted = async () => {
    setOpenSLA(false);
    if (exchangeSelected  && isExchangeFuncEnabled) {//RAC2
      setExchangeSelected(false);
      setExchangeNavigationPopup(true);
    }
    else {
      setOpenOfferLoading(true);
      try {
        const retentionOffersResult = await getRetentionOffers({
          agreementNumbers: [String(choosenAgreementNumber)],
        });
        console.log("retentionOffersResult", retentionOffersResult);
        const collectedLabels: any = [];
        const constantLabels: any = [
          "Product",
          "No Longer Needs",
          "Service",
          "Moving",
        ];
        retentionOffersResult.data?.returnReasons.map((el: any) => {
          collectedLabels.push(el.reason);
        });

        if (retentionOffersResult?.data?.agreementOffers[0]?.exchangeOfferDescription  && isExchangeFuncEnabled) {//RAC2
          collectedLabels.push('RAC Exchange');
        }

        setAvailableOffers([...constantLabels, ...collectedLabels]);
        if (retentionOffersResult.data) {
          setOfferResponse(retentionOffersResult.data);
        }
        setOpenRetention(true);
      } catch {
        setOfferResponse(undefined);
        setAvailableOffers(availableOffers);
        setOpenRetention(true);
      }
      setOpenOfferLoading(false);
    }
  };

  const closeRetentionPopup = async () => {
    setOpenRetention(false);
    setChoosenIndex(undefined);
    setChoosenRetentionOptions(undefined);
    setChoosenOfferReason(undefined);

    const today = new Date();
    const date = today.toISOString().split("T")[0];
    const payloadAgreementActivities: any = [];
    if (choosenOfferReason == "Total RTO/Term too high") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `TRTO high Canceled: sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRTRT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `TRTO high Canceled: sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRTRT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `TRTO high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRTRTO",
        });
      }
    } else if (choosenOfferReason == "SAC too High") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `SAC high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRSACT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `SAC high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRSACT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `SAC high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRSACTH",
        });
      }
    } else if (choosenOfferReason == "EPO too High") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `EPO high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCREPOT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `EPO high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCREPOT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `EPO high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCREPOTH",
        });
      }
    } else if (choosenOfferReason == "Renewal rate too high") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Rate high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRRTH",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Rate high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRRTH",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Rate high Canceled: Not Sel Customer does not accept any offer`,
          agreementActivityType: "CANCRRTH",
        });
      }
    } else if (choosenOfferReason == "Cannot make current payment") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `make paymnt Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRCMCP",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `make paymnt Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRCMCP",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `make paymnt Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCCRCMCP",
        });
      }
    } else if (choosenOfferReason == "Service") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Service: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRSERV",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Service: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRSERV",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Service: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRSERV",
        });
      }
    } else if (choosenOfferReason == "Product") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Product: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRPROD",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Product: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRPROD",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Product: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRPROD",
        });
      }
    } else if (choosenOfferReason == "Moving") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Moving: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRMOVI",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Moving: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRMOVI",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Moving: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRMOVI",
        });
      }
    } else if (choosenOfferReason == "No Longer Needs") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `No Longer Needs: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRNLN",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRNLN",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRNLN",
        });
      }
    } else if (choosenOfferReason == 'RAC Exchange') {//RAC2
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `No Longer Needs: Sel ${el.displayLabel}`,
            agreementActivityType: "AGMXNGCAN",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSXNG",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANXNG",
        });
      }
    }
    if (payloadAgreementActivities.length) {
      try {
        const agreementActivityPayloadObj = {
          agreementActivities: payloadAgreementActivities,
        };
        await postAgreementActivity(agreementActivityPayloadObj);
      } catch {
        // No Catch
      }
    }
  };

  const recordRedirection = async () => {
    setOpenActivityLoading(true);
    const today = new Date();
    const date = today.toISOString().split("T")[0];
    const agreementActivityPayload: any = [];
    if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "EPO"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement EPO Selected",
        agreementActivityType: "AEPOS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "FREETIME"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Extension Selected",
        agreementActivityType: "AEXTNS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "RR"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Rate Adjustment Selected",
        agreementActivityType: "ARAS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "TR"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Term Adjustment Selected",
        agreementActivityType: "ATAS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "SW"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention Switchout Selected",
        agreementActivityType: "AGMRETSWS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "S"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention Service Selected",
        agreementActivityType: "AGMRETSS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
        "StoreManagement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "MOVE"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Moving Selected",
        agreementActivityType: "MS",
      });
    } else if (
      choosenRetentionOptions.displayLabel ==
      "Customer does not accept any offer"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention No Offer Selected",
        agreementActivityType: "AGMRETNONE",
      });
    } else if (
      choosenRetentionOptions.displayLabel ==
      "No retention offer option available"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention No Offer Available",
        agreementActivityType: "RETNOAVAIL",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
      "Inventory"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Exchange Selected",
        agreementActivityType: "AGMXNGS",
      });
    }
    const agreementActivityPayloadObj = {
      agreementActivities: agreementActivityPayload,
    };
    try {
      await postAgreementActivity(agreementActivityPayloadObj);
    } catch {
      // Exception Handle
    }
    if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "EPO"
    ) {
      const epoDiscountAmount = choosenRetentionOptions?.offerValue?.epoDiscount
        .trim()
        .replace(/\$/g, "");
      history.push({
        pathname: `/payment1/paymentinformation/${customerId}/0`,
        search: `?CRTYPE=EPO&SRC=PAYMENT`,
        state: {
          retentionData: {
            newEpo: choosenRetentionOptions?.offerValue?.newEpo,
            epoDiscount: epoDiscountAmount,
            currentEpo: choosenRetentionOptions?.offerValue?.currentEpo,
            agreementNumber: choosenAgreementNumber,
            agreementId: choosenAgreementID,
            retentionEPOMessage: choosenRetentionOptions.displayLabel,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "FREETIME"
    ) {
      history.push({
        pathname: `/payment1/paymentinformation/${customerId}/0`,
        search: `?CRTYPE=FREETIME&SRC=PAYMENT`,
        state: {
          retentionData: {
            extentionDays: choosenRetentionOptions?.offerValue?.extension,
            agreementNumber: choosenAgreementNumber,
            agreementId: choosenAgreementID,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "RR"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=RR`,
        state: {
          retentionData: {
            retentionData: offerResponse,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "TR"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=TR`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "SW"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=SW`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "R"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=R`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "S"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=S`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
        "StoreManagement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "MOVE"
    ) {
      history.push({
        pathname: `/storemgmt/lookupstore`,
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
        "Inventory"
    ) {
      if (isExchangeFuncEnabled) {
        history.push(`/agreement/rental/itemsearch/${customerId}?isExchange=1&agreementId=${choosenAgreementID}`)
      }
      else {
        setExchangeNavigationPopup(true);
      }
    }
    setChoosenOfferReason(undefined);
    setChoosenIndex(undefined);
    setChoosenRetentionOptions(undefined);
    setOpenActivityLoading(false);
  };

  const renderRetentionModal = () => {
    return (
      <>
        <Grid container className={classes.mt2}>
          <Typography
            variant="h6"
            className={`${classes.title} ${classes.mb1}`}
          >
            Please select why the customer wishes to return to see Customer
            Retention Offer
          </Typography>
          <Grid container spacing={2}>
            {availableRetentionOffers?.map((el: any, index: any) => {
              return (
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={`${classes.mb2}`}
                  key={index}
                >
                  <Card
                    className={
                      el == choosenOfferReason
                        ? `${classes.cardTile} ${classes.payScheduleHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                        : `${classes.cardTile} ${classes.payScheduleNotHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                    }
                    onClick={() => {
                      setChoosenOfferReason(el);
                      setChoosenRetentionOptions(undefined);
                      setChoosenIndex(undefined);
                    }}
                  >
                    <CardContent>
                      <Grid container className={classes.justifyLabel}>
                        <Typography
                          className={
                            el == choosenOfferReason
                              ? classes.paySchedulePeriodHighlight
                              : classes.paySchedulePeriodNotHighlight
                          }
                          variant="body1"
                        >
                          {el}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {choosenOfferReason ? (
            <Grid container className={`${classes.mb2}`}>
              <Card
                className={`${classes.card} ${classes.w100} ${classes.alertwidget} ${classes.mb1p}`}
              >
                <Grid
                  item
                  className={`${classes.w100} ${classes.px0} ${classes.floatLeft}`}
                >
                  <Grid container className={`${classes.px2} ${classes.h65}`}>
                    <Grid
                      className={`${classes.w100} ${classes.paymentAlignCenter} ${classes.floatLeft}`}
                    >
                      <Grid
                        item
                        className={`${classes.raccollg6}  ${classes.floatLeft}`}
                      >
                        <Typography
                          component="span"
                          className={`${classes.titletxtstyle} ${classes.bold} ${classes.pe4} ${classes.floatLeft}`}
                        >
                          Retention Option
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={`${classes.px2}`}>
                    {retentionOptions?.map((el: any, index: any) => {
                      return (
                        <Grid
                          className={`${classes.w100} ${classes.paymentAlignCenter} ${classes.floatLeft}`}
                          key={index}
                        >
                          <RACCheckBox
                            size="medium"
                            key={index}
                            onChange={() => {
                              if (choosenOptionIndex != index) {
                                setChoosenIndex(index);
                                setChoosenRetentionOptions(el);
                              } else {
                                setChoosenIndex(undefined);
                                setChoosenRetentionOptions(undefined);
                              }
                              //onCheckBoxClicked(index, el);
                            }}
                            checked={choosenOptionIndex == index ? true : false}
                            label={el.displayLabel}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  className={`${classes.w100} ${classes.px20} ${classes.floatRight} `}
                >
                  <RACButton
                    className={`${classes.floatRight} ${classes.ms2}`}
                    color="primary"
                    variant="contained"
                    disabled={choosenRetentionOptions ? false : true}
                    onClick={() => {
                      if (
                        choosenRetentionOptions?.displayLabel ==
                        "Explain lifetime reinstatement to the customer"
                      ) {
                        setOpenRetention(false);
                        setOpenReinstateAlert(true);
                      } else {
                        recordRedirection();
                        setOpenRetention(false);
                      }
                    }}
                  >
                    Record
                  </RACButton>
                  <RACButton
                    className={`${classes.floatRight} ${classes.ms2}`}
                    color="primary"
                    variant="outlined"
                    onClick={() => closeRetentionPopup()}
                  >
                    Cancel
                  </RACButton>
                </Grid>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  };

  const offerLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Fetching Retention Offers
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>
            Please wait...
          </Typography>
        </Grid>
      </>
    );
  };

  const activityLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Saving Information
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>
            Please wait...
          </Typography>
        </Grid>
      </>
    );
  };
  const FirstRowDependency = () => {
    return (
      <Grid container spacing={2}>
        <Grid item lg={2} md={4} sm={6}>
          <RACTextbox
            type="text"
            name="LastName"
            value={searchData.LastName}
            inputlabel="Last Name"
            maxlength={30}
            OnChange={(e: any) => HandleChange(e)}
            isCurrency={false}
            onKeyPress={(e: any) => enterKeyPressed(e)}
          />
        </Grid>
        <Grid item lg={2} md={4} sm={6}>
          <RACTextbox
            type="text"
            name="FirstName"
            inputlabel="First Name"
            value={searchData.FirstName}
            maxlength={30}
            OnChange={(e: any) => HandleChange(e)}
            isCurrency={false}
            onKeyPress={(e: any) => enterKeyPressed(e)}
          />
        </Grid>
        <Grid item md={2} className={`${classes.datePicker} ${classes.spacerMT1}`}>
          <RACDatePicker
            id="a11y_dob"
            label="Date of Birth"
            value={searchData.Dob}
            name={"Dob"}
            onChange={(e: any) => HandleChange(e, "DOB")}
            onKeyDown={(e: any) => enterKeyPressed(e)}
            inputProps={{
              min: moment()
                .subtract(110, "years")
                .format("YYYY-MM-DD"),
              max: moment()
                .subtract(18, "years")
                .format("YYYY-MM-DD"),
            }}
          />
        </Grid>
        <Grid item lg={2} md={4} sm={6} className={classes.spacerPT1}>
          <label htmlFor="a11y_ssn" className={classes.formLabel}>
            Social Security# / ITIN#
          </label>
          <Grid container spacing={2} className={classes.ssnpadding}>
            <Grid
              item
              md={4}
              sm={4}
              className={`${classesManageCustomerStyles.ssnPaddingTop}`}
            >
              <RACTextbox
                type="password"
                name="Ssn1"
                value={searchData.Ssn1}
                placeHolder={"xxx"}
                id={"a11y_ssnone"}
                className={classes.textCenterSSN}
                maxlength={3}
                OnChange={(e) => HandleChange(e)}
                isCurrency={false}
                onKeyPress={(e: any) => enterKeyPressed(e)}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={3}
              className={`${classesManageCustomerStyles.ssnPaddingTop}`}
            >
              <RACTextbox
                type="password"
                name="Ssn2"
                value={searchData.Ssn2}
                placeHolder={"xx"}
                id={"a11y_ssntwo"}
                className={classes.textCenterSSN}
                formControlClassName={classes.ssnTextField}
                maxlength={2}
                OnChange={(e) => HandleChange(e)}
                isCurrency={false}
                onKeyPress={(e: any) => enterKeyPressed(e)}
              />
            </Grid>
            <Grid
              item
              md={5}
              sm={5}
              className={`${classesManageCustomerStyles.ssnPaddingTop}`}
            >
              <RACTextbox
                type="text"
                name="Ssn3"
                value={searchData.Ssn3}
                placeHolder={"xxxx"}
                id={"a11y_ssnthree"}
                className={classes.textCenterSSN}
                maxlength={4}
                OnChange={(e) => HandleChange(e)}
                isCurrency={false}
                onKeyPress={(e: any) => enterKeyPressed(e)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} md={4} sm={6}>
          <RACTextbox
            type="phoneno"
            maxlength={14}
            inputlabel="Phone Number"
            id="a11y_phonenumber"
            name="phoneNumber"
            value={searchData.Phonenumber}
            OnChange={(e) => HandleChange(e)}
            isCurrency={false}
            onKeyPress={(e: any) => enterKeyPressed(e)}
          />
        </Grid>
        <Grid item lg={2} md={4} sm={6}>
          <RACTextbox
            type="text"
            maxlength={19}
            inputlabel="Agreement #"
            id="a11y_agreementumber"
            name="agreementNumber"
            value={searchData.agreementNumber}
            OnChange={(e: any) => HandleChange(e)}
            isCurrency={false}
            onKeyPress={(e: any) => enterKeyPressed(e)}
          />
        </Grid>
      </Grid>
    );
  }

  const secondRowFirstDependency = () => {
    return (
      <Grid container spacing={2} md={4}>
        <Grid item md={6}>
          <RACTextbox
            type="text"
            name="addressLine1"
            placeHolder={'Address Line 1'}
            inputlabel="Address Line 1"
            maxlength={30}
            value={searchData.addressLine1}
            inputLabelClassname={classes.mb1}
            OnChange={(e) => HandleChange(e)}
            OnKeydown={(e: any) => {
              enterKeyPressed(e);
            }}
          />
        </Grid>
        <Grid item md={6}>
          <RACTextbox
            type="text"
            name="addressLine2"
            placeHolder={'Address 2, APT #, Unit #, P.O. Box'}
            inputlabel="Address Line 2"
            value={searchData.addressLine2}
            inputLabelClassname={classes.mb1}
            maxlength={30}
            OnChange={(e) => HandleChange(e)}
            OnKeydown={(e: any) => {
              enterKeyPressed(e);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  const secondRowSecondDependency = () => {
    return (
      <Grid container spacing={2} className={`${classes.ms2}`} md={4}>
        <Grid item md={4}>
          <RACTextbox
            type="text"
            name="zip"
            inputlabel="Zip"
            placeHolder={'Zip code'}
            maxlength={10}
            minlength={5}
            value={searchData.zip}
            inputLabelClassname={classes.mb1}
            OnChange={(e) => {
              let zipCode = ('' + e.target.value).replace(/\D/g, '');
              zipCode =
                zipCode.length > 5
                  ? zipCode.slice(0, 5) + '-' + zipCode.slice(5)
                  : zipCode;
              e.target.name = 'zip';
              e.target.value = zipCode;
              HandleChange(e)
            }}
            OnKeydown={(e: any) => {
              enterKeyPressed(e);
            }}
          />
        </Grid>

        <Grid item md={4}>
          <RACTextbox
            type="text"
            name="city"
            inputlabel="City"
            value={searchData.city}
            placeHolder={'City'}
            maxlength={30}
            inputLabelClassname={classes.mb1}
            OnChange={(e) => HandleChange(e)}
            OnKeydown={(e: any) => {
              if (e.key == 'Enter') {
                enterKeyPressed(e);
              }
            }}
          />
        </Grid>
        <Grid item md={4}>
          <RACSelect
            loading={dropDownLoading}
            inputLabel={'State'}
            options={states}
            inputLabelClassName={classes.mb1}
            name="state"
            defaultValue={searchData.state}
            onChange={(e) => {
              e.target.name = `state`;
              HandleChange(e)}}
          />
        </Grid>
      </Grid>
    );
  }

  const secondRowThirdDependency = () => {
    return (
      <Grid container spacing={2} className={`${classes.ms2}`} md={4}>
        <Grid item md={10} className={`${classes.mt2}`}>

          <RACButton
            disabled={disableSearchBtn}
            variant="contained"
            color="primary"
            size="small"
            className={`${classes.floatRight} ${classes.ms2} ${classes.mt2}`}
            onClick={() => showSearchResults()}
          >
            <SearchImageDefault
              className={classes.racGlobalSearchBtn}
            ></SearchImageDefault>
            Search
          </RACButton>
          <RACButton
            variant="outlined"
            color="primary"
            className={`${classes.floatRight} ${classes.p10p25} ${classes.mt2}`}
            disabled={
              searchData.FirstName.length == 0 &&
                searchData.LastName.length == 0 &&
                searchData.Dob == "" &&
                searchData.Ssn1.length == 0 &&
                searchData.Ssn2.length == 0 &&
                searchData.Ssn3.length == 0 &&
                searchData.Phonenumber.length == 0 &&
                searchData.agreementNumber.length == 0 &&
                searchData.addressLine1.length == 0 &&
                searchData.addressLine2.length == 0 &&
                searchData.zip.length == 0 &&
                searchData.city.length == 0 &&
                searchData.state == '0'
                ? true
                : false
            }
            data-testid="clrbtn"
            onClick={() => {
              clearbtnClick();
            }}
          >
            Clear
          </RACButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <GetCustIDAlert />
      <EnterSSNfn />
      <Dedecline />
      <NoDEReason />
      {/* <CreateAgreementrMsg /> */}
      {/* <AddCoCustomerPopup /> */}
      <ComingSoonMsg />
      <CreateCustomerModal />
      <AgreementMandatoryFieldpopup />
      <Modal
        isOpen={blurLoaderActive}
        className={classes.loaderStyle}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div>
          <div className={classes.RACLoaderPage}>
            <CircularProgress />
          </div>
        </div>
      </Modal>
      <div className={classes.racFixedFooterMargin}>
        <Grid item md={12}>
          <List className={`${classes.breadcrumb} ${classes.py1}`}>
            <ListItem className={classes.breadcrumbItem}>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => history.push(`/dashboard`)}
              >
                Dashboard
              </a>
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem2} ${classes.breadcrumbArrow}`}
            >
              Payment
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem1} ${classes.breadcrumbArrow}`}
            >
              Payment Search
            </ListItem>
          </List>
        </Grid>
        <Grid container className={`${classes.px2} ${classes.mb3}`}>
          <Grid item md={12}>
            <Typography
              className={`${classes.widgetTitle} ${classes.font16} ${classes.spacerMB1}`}
            >
              Payment Search Criteria
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Card className={classes.card}>
              <CardContent>
              {FirstRowDependency()}
                <Grid container md={12} className={classes.pt4}>
                  {secondRowFirstDependency()}
                  {secondRowSecondDependency()}
                  {secondRowThirdDependency()}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {initialNoRecPopUp ? (
          ""
        ) : searchResults.length > 0 && EnableLoaderButton === false ? (
          <>
            <Grid container className={`${classes.px2} ${classes.mb2}`}>
              <Grid item md={6}>
                <Typography
                  className={`${classes.widgetTitle} ${classes.font16} ${classes.spacerMB1}`}
                >
                  Search Results
                </Typography>
              </Grid>
              {/* <Grid item md={6} className={classes.textRight}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => CreateCustomer()}
                >
                  Create Customer
                </Button>
              </Grid> */}
            </Grid>
            <Grid container className={`${classes.px2} ${classes.mb2}`}>
              <Card className={classes.card}>
                <CardContent className={classes.spacerP0}>
                  <Grid item md={12} className={classes.racGrid}>
                    <RACTable
                      renderTableHead={renderSearchTableHead}
                      renderTableContent={renderSearchTableContent}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </>
        ) : EnableLoaderButton === false ? (
          <Grid item md={12} className={classes.px2}>
            <Grid
              container
              className={`${classes.justifyContentCenter} ${classes.mb2}`}
            >
              <Alerticon style={{ width: "30px", height: "30px" }} />
              {!isUnableTofetchData ? (
                <Box className={`${classes.ms2}`} style={{ marginTop: "5px" }}>
                  No Records Found
                </Box>
              ) : null}
            </Grid>
            {/* <Grid item md={12}>
              <Typography
                className={`${classes.RACPOPMsg} ${classes.textCenter}`}
              >
                {isUnableTofetchData ? (
                  <Box>Unable to fectch customer info</Box>
                ) : (
                  <Box>
                    Do you want to create{' '}
                    <Typography
                      variant="caption"
                      className={`${classes.bold} ${classes.font16}`}
                    >
                      {noSearchResult}
                    </Typography>
                    as a new customer ?
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid
              container
              className={`${classes.justifyContentCenter} ${classes.mt4}`}
            >
              {isUnableTofetchData ? (
                <RACButton
                  color="primary"
                  variant="contained"
                  onClick={() => showSearchResults()}
                >
                  Retry
                </RACButton>
              ) : (
                <RACButton
                  color="primary"
                  variant="contained"
                  onClick={() => CreateCustomer()}
                >
                  Create customer
                </RACButton>
              )}
            </Grid> */}
          </Grid>
        ) : null}
      </div>
      <Grid
        item
        classes={{
          root: clsx(classes.customerToolbar, classes.fixedBottom),
        }}
        style={{ zIndex: 0 }}
      >
        <Grid item className={classes.floatLeft}>
          <RACButton
            className={classes.spacerMR2}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              history.push(`/dashboard`);
            }}
          >
            Cancel
          </RACButton>
        </Grid>
        <Grid item className={classes.floatRight}>
          {searchResults && searchResults.length > 0 ? (
            <>
              <RACButton
                size="small"
                variant="contained"
                color="primary"
                disabled={newCustValidBtnHidden}
                className={classes.spacerMR2}
                onClick={() => runDecisionEngine()}
              >
                Run Decision Engine
              </RACButton>

              {paymentFunctionFlag.reversePayment == 1 ? (
                <RACButton
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={newCustValidBtnHidden}
                  className={classes.spacerMR2}
                  onClick={() => ReversePaymentRedirect()}
                >
                  Reverse Payment
                </RACButton>
              ) : null}

              <RACButton
                variant="contained"
                color="primary"
                className={classes.spacerMR2}
                disabled={newCustValidBtnHidden}
                onClick={() => paymentRedirect()}
              >
                {paymentFunctionFlag.payFunc == 0 &&
                paymentFunctionFlag.isCashSale == 1
                  ? "Non-Inventory Sale"
                  : "Take Payment"}
              </RACButton>
            </>
          ) : null}
        </Grid>
      </Grid>

      <RACModalCard
        isOpen={openOfferLoading}
        maxWidth="xs"
        borderRadius={"25px !important"}
        //eslint-disable-next-line react/no-children-prop
        children={offerLoadingRenderer()}
      />

      <RACModalCard
        isOpen={openRetention}
        maxWidth="xl"
        title="Customer Retention"
        borderRadius={"25px !important"}
        closeIcon={true}
        onClose={() => closeRetentionPopup()}
        /* eslint-disable react/no-children-prop */
        children={renderRetentionModal()}
      />

      <RACModalCard
        isOpen={openReinstateAlert}
        maxWidth="sm"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={buildReinstateAlert()}
      />

      <RACModalCard
        isOpen={openActivityLoading}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={activityLoadingRenderer()}
      />

      {
        exchangeNavigationPopup ? (
          <RACExchange value={{
            customerId: customerId,
            agreementId: choosenAgreementID,
            rentPaid: rentPaid,
            isExchangeFuncEnabled : isExchangeFuncEnabled,
            suspenseAmount : suspenseAmount,
            exchangeAgreementNumber: exchangeAgreementNumber
          }} />
        ) : null
      }

      {openSLA === true ? (
        <SecondFactor
          setTwoFactorCancelClick={SLACanceled}
          setTwoFactorCompleted={SLACompleted}
          CurrentRole={currentRole}
          moduleName={exchangeSelected ? 'RACExchange' : "CustomerRetention"}
        />
      ) : null}
    </>
  );
}
